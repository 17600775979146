import {
  useRef,
  useState,
  useEffect,
  useMemo,
  createRef,
  RefObject,
  useCallback,
} from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import {
  Dropdown,
  tailwindOverride,
  Tooltip,
  Checkbox,
} from '@getsynapse/design-system';
import { ScheduleProjectOption } from 'utils/types/learningSchedule';
import { Option } from 'utils/customTypes';
import { getStatusColumn } from 'Pages/ProjectsListPage/helpers/tableColumnsValues';
import { fetchProjects, selectAllProjects } from 'state/Schedule/ScheduleSlice';

type ProjectsDropdownProps = {
  onChange: (options: Option[]) => void;
  value: string[];
  projectsData: ScheduleProjectOption[];
};

const ProjectsDropdown = ({
  onChange,
  value,
  projectsData,
}: ProjectsDropdownProps) => {
  const dispatch = useDispatch();
  const processRefs = useRef<RefObject<HTMLLIElement>[]>([]);

  const projects = useSelector(selectAllProjects);

  const [truncatedItems, setTruncatedItems] = useState<boolean[]>([]);
  const [isDropdownFirstOpen, setIsDropdownFirstOpen] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedValues, setSelectedValues] = useState<Option[]>([]);

  const dropdownOptions = useMemo(() => {
    const existingOptions = projects.map((project: ScheduleProjectOption) => ({
      label: project.title,
      value: project.id,
    }));

    const newOptions = projectsData
      .filter(
        (project) =>
          !existingOptions.some((option) => option.value === project.id)
      )
      .map((project) => ({
        label: project.title,
        value: project.id,
      }));

    return [...existingOptions, ...newOptions];
  }, [projects, projectsData]);

  useEffect(() => {
    if (!searchValue) {
      const filteredOptions = dropdownOptions.filter((option) =>
        value.includes(option.value)
      );
      setSelectedValues(filteredOptions);
    }
  }, [searchValue, dropdownOptions, value]);

  useEffect(() => {
    const findProjects = debounce(async () => {
      dispatch(fetchProjects(searchValue));
    }, 300);

    findProjects();

    return () => {
      findProjects.cancel();
    };
  }, [searchValue, dispatch]);

  useEffect(() => {
    processRefs.current = dropdownOptions!.map(
      (_, i) => processRefs.current[i] ?? createRef()
    );
  }, [dropdownOptions]);

  useEffect(() => {
    if (isDropdownFirstOpen) {
      requestAnimationFrame(() => {
        const truncationStates = processRefs.current.map((ref) => {
          const item = ref.current;
          return item ? item.scrollWidth > item.clientWidth : false;
        });
        setTruncatedItems(truncationStates);
      });
    }
  }, [isDropdownFirstOpen]);

  const getProjectStatus = useCallback(
    (value: string) => {
      return (
        projects.find((project) => project.id === value)?.status ||
        projectsData.find((project) => project.id === value)?.status
      );
    },
    [projects, projectsData]
  );

  const renderOption = useCallback(
    (
      option: Option,
      selected: boolean,
      selectOption: (option: Option) => void,
      { className, ...otherProps }
    ) => {
      const index = dropdownOptions.findIndex(
        (opt) => opt.value === option.value
      );
      const isCurrentItemTruncated = truncatedItems[index];

      const optionItem = (
        <li
          {...otherProps}
          ref={processRefs.current[index]}
          className={tailwindOverride('flex justify-between	group', className, {
            'hover:bg-secondary-lightest hover:shadow-list-item-hover-green':
              !selected,
            'hover:bg-primary focus-visible:bg-primary': selected,
          })}
          onClick={() => {
            selectOption(option);
            setSearchValue('');
          }}
        >
          <Checkbox
            checked={selected}
            label={option.label}
            id='projects-dropdown-checkbox'
            inputProps={{
              className: tailwindOverride('mt-0', {
                'group-hover:border-neutral-white group-focus-visible:border-neutral-white':
                  selected,
                'group-hover:border-secondary-dark group-focus-visible:border-secondary-dark':
                  !selected,
              }),
            }}
            className={tailwindOverride('inline-block', {
              'group-hover:text-secondary-darker group-focus-visible:text-secondary-darker':
                !selected,
              'group-hover:text-neutral-white group-focus-visible:text-neutral-white':
                selected,
              truncate: isCurrentItemTruncated,
            })}
          />
          {getStatusColumn(getProjectStatus(option.value))}
        </li>
      );

      return isCurrentItemTruncated ? (
        <Tooltip
          className='w-full'
          ariaId='dropdown-tooltip'
          openMode='hover1'
          timeout={0}
          trigger={optionItem}
          usePortal={true}
          showPopper={true}
          contentProps={{ className: 'z-high text-sm' }}
        >
          {option.label}
        </Tooltip>
      ) : (
        optionItem
      );
    },
    [dropdownOptions, truncatedItems, getProjectStatus]
  );

  return (
    <Dropdown
      onClick={() => {
        if (!isDropdownFirstOpen) setIsDropdownFirstOpen(true);
      }}
      values={selectedValues}
      className='w-124'
      onChange={onChange}
      options={dropdownOptions}
      filterable
      multiple
      triggerProps={{
        'data-testid': 'projects-dropdown-trigger',
      }}
      listProps={{
        'data-testid': 'projects-dropdown-list',
      }}
      renderOption={renderOption}
      placeholder={intl.get(
        'SCHEDULE.EVENTS.DETAILS_SECTION.PROJECT_PLACEHOLDER'
      )}
      onFilterChange={(value) => setSearchValue(value)}
    />
  );
};

export default ProjectsDropdown;
