import intl from 'react-intl-universal';
import { ChangeEvent, useRef, useCallback, useEffect } from 'react';
import { FormItem, TextArea, Typography } from '@getsynapse/design-system';

type GoalTitleProps = {
  value?: string;
  isRequired?: boolean;
  onChange: (value: string, prop: string) => void;
};

const GoalTitle = ({ onChange, value, isRequired = true }: GoalTitleProps) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleAutoResize = useCallback(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      const remHeight =
        textarea.scrollHeight /
        parseFloat(getComputedStyle(document.documentElement).fontSize);
      const newHeight = remHeight > 2.625 ? `${remHeight}rem` : '2.625rem';
      textarea.style.height = newHeight;
    }
  }, []);

  useEffect(() => {
    handleAutoResize();
  }, [handleAutoResize, isRequired]);

  return (
    <FormItem
      label={intl.get('STRATEGY_GOALS.ADD_GOAL_MODAL.GOAL_TITLE')}
      labelProps={{
        required: isRequired,
        'data-cy': 'goal-title_label',
      }}
      className='mb-4'
    >
      {isRequired && (
        <TextArea
          ref={textareaRef}
          value={value}
          textAreaProps={{
            placeholder: intl.get(
              'STRATEGY_GOALS.ADD_GOAL_MODAL.GOAL_TITLE_PLACEHOLDER'
            ),
            rows: 1,
            className: 'overflow-hidden placeholder-neutral',
            'data-testid': 'add_goal_title_input',
            style: { height: '2.625rem' },
          }}
          name='add_goal_title_input'
          onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
            handleAutoResize();
            onChange(event.target.value, 'title');
          }}
        />
      )}
      {!isRequired && (
        <Typography variant='body' data-cy='goal-title_value'>
          {value}
        </Typography>
      )}
    </FormItem>
  );
};

export default GoalTitle;
