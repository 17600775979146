import config from 'config/Config';

export const getFileStackConfig = () => {
  const baseConfig = config.get('fileStack');
  const { sources, ...otherConfig } = baseConfig;
  return {
    ...otherConfig,
    apiKey: process.env.REACT_APP_FILESTACK_API_KEY,
    policy: process.env.REACT_APP_FILESTACK_POLICY,
    signature: process.env.REACT_APP_FILESTACK_SIGNATURE,
    fromSources: ['local_file_system'],
    maxSize: 1073741824,
    storeTo: {
      location: 'S3',
      container: config.get('s3BucketName'),
      region: config.get('authentication.region'),
    },
  };
};
