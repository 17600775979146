import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import isEqual from 'lodash/isEqual';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ProjectContent, Task, Option } from 'utils/customTypes';
import { showNotificationBanner } from 'state/InlineNotification/inlineNotificationSlice';
import {
  updateProjectFileLinkedTasks,
  updateContentFilesLinkedTasks,
} from 'state/Project/projectSlice';
import {
  addContentToTask,
  removeContentFromTask,
} from 'state/LinkedTaskContent/linkedTaskContentSlice';
import useModal from 'Hooks/useModal';
import LinkV1Tasks from '../../AddContentModal/components/linkTasks/LinkV1Tasks';
import LinkV2Tasks from '../../AddContentModal/components/linkTasks/LinkV2Tasks';

const LinkUnlinkToTaskModal: React.FC<{
  projectId: string;
  isModalOpen: boolean;
  onCloseModal: () => void;
  contentFile: ProjectContent | null;
}> = ({ isModalOpen, onCloseModal, contentFile, projectId }) => {
  const { newTaskListViews = false } = useFlags();
  const dispatch = useDispatch();
  const { Modal, modalProps, openModal, closeModal } = useModal();
  const [originalLinkedTasksIds, setOriginalLinkedTasksIds] = useState<
    string[]
  >([]);
  const [linkedTasks, setLinkedTasks] = useState<Option[]>([]);
  const LinkTasksComponent = newTaskListViews ? LinkV2Tasks : LinkV1Tasks;

  const canSubmit = useMemo(() => {
    const currentLinkedTasksIds = linkedTasks?.map(
      (linkedTask: Option) => linkedTask.value
    );
    return !isEqual(currentLinkedTasksIds, originalLinkedTasksIds);
  }, [originalLinkedTasksIds, linkedTasks]);

  useEffect(() => {
    if (isModalOpen && !modalProps.isOpen) {
      openModal(modalProps);
    }
  }, [isModalOpen, modalProps, openModal]);

  const v1UpdateLinkedTasks = useCallback(async () => {
    const linkedTasksIds = linkedTasks.map((task: Option) => task.value);
    await dispatch(
      updateProjectFileLinkedTasks({
        projectId,
        data: { linkedTasksIds, fileId: contentFile?.id! },
      })
    );
  }, [dispatch, contentFile?.id, projectId, linkedTasks]);

  const v2UpdateLinkedTasks = useCallback(async () => {
    const linkedTaskIds = linkedTasks.map((task: Option) => task.value);
    const newLinkedTasks = linkedTasks.filter(
      (linkedTask: Option) =>
        !contentFile?.linkedTasksIds?.includes(linkedTask.value)
    );
    const removedLinkedTasks =
      contentFile?.linkedTasksIds?.filter(
        (linkedTaskId: string) => !linkedTaskIds.includes(linkedTaskId)
      ) ?? [];
    for (const task of newLinkedTasks) {
      await dispatch(
        addContentToTask({
          taskId: task.value,
          contentIds: [contentFile?.id!],
        })
      );
    }
    for (const task of removedLinkedTasks) {
      await dispatch(
        removeContentFromTask({
          taskId: task,
          contentIds: [contentFile?.id!],
        })
      );
    }
    dispatch(
      updateContentFilesLinkedTasks({
        fileId: contentFile?.id!,
        linkedTasks: linkedTasks.map((task: Option) => ({
          id: task.value,
          name: task.label,
          createdAt: new Date().toLocaleDateString('en-US'),
        })),
      })
    );
  }, [dispatch, contentFile?.id, contentFile?.linkedTasksIds, linkedTasks]);

  useEffect(() => {
    if (contentFile !== null) {
      if (contentFile.linkedTasks) {
        setLinkedTasks(
          contentFile.linkedTasks.map((task: Task) => ({
            label: task.name,
            value: task.id,
          }))
        );
      }
      if (contentFile.linkedTasksIds) {
        setOriginalLinkedTasksIds(contentFile.linkedTasksIds);
      }
    }
  }, [contentFile]);

  const handleCloseModal = useCallback(() => {
    onCloseModal();
    closeModal();
  }, [closeModal, onCloseModal]);

  const handleOnConfirm = useCallback(async () => {
    if (newTaskListViews) {
      await v2UpdateLinkedTasks();
    } else {
      await v1UpdateLinkedTasks();
    }
    dispatch(
      showNotificationBanner({
        notificationVariant: 'success',
        notificationText: intl.get(
          'PROJECT_DETAIL.FILES_TAB.LINK_UNLINK_TO_TASK_MODAL.SUBMIT_SUCCESS_MESSAGE'
        ),
      })
    );
    handleCloseModal();
  }, [
    handleCloseModal,
    v1UpdateLinkedTasks,
    v2UpdateLinkedTasks,
    newTaskListViews,
    dispatch,
  ]);

  const actionButtons = [
    {
      children: intl.get('SAVE'),
      variant: 'primary',
      onClick: handleOnConfirm,
      disabled: !canSubmit,
      'data-cy': 'link-unlink-task-modal__submit-button',
    },
    {
      children: intl.get('CANCEL'),
      variant: 'tertiary',
      onClick: handleCloseModal,
      'data-cy': 'link-unlink-task-modal__cancel-button',
    },
  ];

  return (
    <Modal
      {...modalProps}
      title={intl.get(
        'PROJECT_DETAIL.FILES_TAB.LINK_UNLINK_TO_TASK_MODAL.TITLE'
      )}
      aria-label={intl.get(
        'PROJECT_DETAIL.FILES_TAB.LINK_UNLINK_TO_TASK_MODAL.TITLE'
      )}
      closeModal={handleCloseModal}
      actionButtons={actionButtons}
      size='large'
      data-cy='link-unlink-task-modal'
    >
      <LinkTasksComponent
        projectId={projectId}
        selectedTasks={linkedTasks}
        setSelectedTasks={setLinkedTasks}
      />
    </Modal>
  );
};

export default LinkUnlinkToTaskModal;
