import { FC, useMemo, useState, useCallback } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { Table, tailwindOverride } from '@getsynapse/design-system';
import { TaskWithAssignees } from 'types/store/projectTasksList';
import { TaskListFilters } from 'types/store/tasksList';
import { ProjectTasksTableTab } from 'utils/customTypes';
import { TASK_STATUS } from 'utils/constants';
import useFilterSettings from './hooks/useFilterSettings';
import TableHeader from './TableHeader/TableHeader';
import TableBody from './TableBody/TableBody';
import TableFooter from 'Pages/TasksListPage/components/v2/TasksListTable/TableFooter/TableFooter';
import ActionsTableHeader from './ActionsTableHeader/ActionsTableHeader';
import TableFilters from './TableFilters/TableFilters';
import SkeletonLoader from 'Organisms/TableSkeletonLoader/PageTableLoader';

interface TasksTableProps {
  tableName: ProjectTasksTableTab;
  tasks: TaskWithAssignees[];
  totalCount?: number;
  canFetchMoreTasks: boolean;
  fetchMoreTasks: () => void;
  reorderTask?: (taskId: string, startIndex: number, endIndex: number) => void;
  canReorderTasks?: boolean;
  isLoading?: boolean;
  filters: TaskListFilters;
  onUpdateFilters: (filters: TaskListFilters) => void;
  onSearchFilterChange: (value: string) => void;
  onExport: () => void;
}

const TasksTable: FC<TasksTableProps> = ({
  tableName,
  tasks,
  totalCount,
  canFetchMoreTasks,
  fetchMoreTasks,
  canReorderTasks = false,
  isLoading = false,
  reorderTask,
  filters,
  onUpdateFilters,
  onSearchFilterChange,
  onExport,
}) => {
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const showDisabledColumn = useMemo(
    () =>
      tasks.some(
        (task) => task.disabled || task.status === TASK_STATUS.COMPLETED
      ),
    [tasks]
  );

  const isListEmpty = tasks.length === 0;

  const toggleIsDragging = () => {
    setIsDragging(!isDragging);
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination || !reorderTask) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    reorderTask(
      result.draggableId,
      result.source.index,
      result.destination.index
    );
    toggleIsDragging();
  };

  const resetTableScroll = useCallback(() => {
    const element = document.getElementById(tableName) as HTMLElement;
    if (element) {
      element.scrollTop = 0;
    }
  }, [tableName]);

  const handleFiltersUpdate = useCallback(
    (updatedFilters: TaskListFilters) => {
      onUpdateFilters(updatedFilters);
      resetTableScroll();
    },
    [onUpdateFilters, resetTableScroll]
  );

  const handleSearchFilterChange = useCallback(
    (value: string) => {
      onSearchFilterChange(value);
      resetTableScroll();
    },
    [onSearchFilterChange, resetTableScroll]
  );

  const { setFiltersSettings } = useFilterSettings({
    tableName,
    updateFiltersCallback: handleFiltersUpdate,
  });

  return (
    <>
      <TableFilters filters={filters} onUpdateFilters={setFiltersSettings} />
      {isLoading && (
        <SkeletonLoader data-testid={`${tableName}__skeleton-loader`} />
      )}
      {!isLoading && (
        <DragDropContext
          onDragStart={toggleIsDragging}
          onDragEnd={handleDragEnd}
        >
          <ActionsTableHeader
            tableName={tableName}
            filters={filters}
            onUpdateFilters={setFiltersSettings}
            onSearchFilterChange={handleSearchFilterChange}
            onExport={onExport}
          />
          <div
            className={tailwindOverride(
              'w-full max-h-centralized-tasks-table',
              'rounded-b border-neutral-lighter-two border border-t-0',
              'overflow-auto'
            )}
            id={tableName}
          >
            <Table
              className={tailwindOverride('relative', {
                'max-w-full border-0': !isListEmpty,
                'w-full': isListEmpty,
              })}
              canSelectRows
              isSelectRowCellSticky={true}
              aria-label={tableName}
              data={{
                headData: {
                  headCells: [],
                },
                rows: [],
              }}
            >
              <TableHeader
                showDisabledColumn={showDisabledColumn}
                isListEmpty={tasks.length === 0}
              />
              <TableBody
                tableName={tableName}
                tasks={tasks}
                showDisabledColumn={showDisabledColumn}
                canFetchMoreTasks={canFetchMoreTasks}
                fetchMoreTasks={fetchMoreTasks}
                canReorderTasks={canReorderTasks}
                isDragging={isDragging}
              />
            </Table>
          </div>
          <TableFooter total={totalCount} />
        </DragDropContext>
      )}
    </>
  );
};

export default TasksTable;
