import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import intl from 'react-intl-universal';
import isNumber from 'lodash/isNumber';
import {
  Typography,
  LargeLabelInput,
  FormItem,
  NumericInput,
  LargeLabel,
  Tooltip,
  IconButton,
  Icon,
} from '@getsynapse/design-system';
import { store } from 'state/store';
import { showNotification as showSnackbarNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';
import Divider from 'Atoms/Divider';
import { AdditionalBenefit, MonetaryBenefitsType } from 'utils/types/program';
import { NUMERICAL_FIELD_MAX_VALUE, ROI_METHODOLOGIES } from 'utils/constants';
import OpenLink from 'assets/images/opne-link.svg';
import { getROIFileSignedUrl } from 'state/ProgramStrategy/programStrategySlice';

interface Props {
  monetaryBenefitsData: MonetaryBenefitsType | AdditionalBenefit;
  setMonetaryBenefitsData: React.Dispatch<
    React.SetStateAction<MonetaryBenefitsType | AdditionalBenefit>
  >;
  setAreNumericFieldsValid: React.Dispatch<React.SetStateAction<boolean>>;
}

const MonetaryBenefits = ({
  monetaryBenefitsData,
  setMonetaryBenefitsData,
  setAreNumericFieldsValid,
}: Props) => {
  const dispatch = useDispatch() as typeof store.dispatch;

  const { programId } = useParams<{
    programId: string;
  }>();

  const [annualBenefit, setAnnualBenefit] = useState<number | null>(null);

  useEffect(() => {
    setAnnualBenefit(monetaryBenefitsData?.annualBenefit!);
  }, [monetaryBenefitsData?.annualBenefit]);
  const [numericFieldsState, setNumericFieldsState] = useState<boolean[]>(
    Array(2).fill(true)
  );

  useEffect(() => {
    setAreNumericFieldsValid(numericFieldsState.every((isValid) => isValid));
  }, [numericFieldsState, setAreNumericFieldsValid]);

  const updateBenefits = (key: string, value: string | number | null) => {
    setMonetaryBenefitsData((prev) => ({
      ...prev!,
      [key]: value,
    }));
    if (
      key === 'valueOfOneUnitOfMeasure' ||
      key === 'changeInPerformancePerMonth'
    ) {
      if (isNumber(value)) {
        if (
          key === 'valueOfOneUnitOfMeasure' &&
          isNumber(monetaryBenefitsData?.changeInPerformancePerMonth)
        ) {
          setAnnualBenefit(
            value * monetaryBenefitsData?.changeInPerformancePerMonth * 12
          );
        } else if (
          key === 'changeInPerformancePerMonth' &&
          isNumber(monetaryBenefitsData?.valueOfOneUnitOfMeasure)
        ) {
          setAnnualBenefit(
            value * monetaryBenefitsData?.valueOfOneUnitOfMeasure * 12
          );
        }
      } else {
        setAnnualBenefit(null);
      }
    }
  };

  const openLink = async () => {
    try {
      const { signedUrl } = await dispatch(
        getROIFileSignedUrl({
          programId,
          fileName: ROI_METHODOLOGIES.IMPACT[0],
        })
      ).unwrap();

      window.open(signedUrl, '_blank');
    } catch (error) {
      dispatch(
        showSnackbarNotification({
          autoHide: false,
          notificationVariant: 'error',
          notificationTitle: intl.get(
            'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.BENEFITS.ERROR_MESSAGE_TITLE'
          ),
          notificationMessage: intl.get(
            'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.BENEFITS.ERROR_MESSAGE_BODY'
          ),
        })
      );
    }
  };

  return (
    <div
      className='px-8 pb-4 w-60-percent-plus-80-px'
      data-testid='monetary-benefits-section'
    >
      <Typography variant='h5' className='mb-1'>
        {intl.get('PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.BENEFITS.TITLE')}
      </Typography>
      <Typography variant='body2' className='text-neutral-darker'>
        {intl.get('PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.BENEFITS.SUBTITLE')}
      </Typography>
      <div className='grid grid-cols-2 mt-6 gap-x-6 gap-y-4'>
        <LargeLabelInput
          value={monetaryBenefitsData?.unitOfMeasure!}
          onChange={(event) =>
            updateBenefits(
              'unitOfMeasure',
              event.target.value ? event.target.value : null
            )
          }
          name='unit-of-measure-input'
          label={intl.get(
            'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.BENEFITS.UNIT_OF_MEASURE_TITLE'
          )}
          labelProps={{
            className: 'text-base mb-0.5',
          }}
          subtitle={intl.get(
            'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.BENEFITS.UNIT_OF_MEASURE_SUBTITLE'
          )}
          subtitleProps={{ className: 'text-neutral-darker h-10' }}
          textAreaProps={{
            placeholder: intl.get(
              'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.BENEFITS.UNIT_OF_MEASURE_PLACEHOLDER'
            ),
            className: 'mt-0 placeholder-text-neutral',
            'data-testid': 'unit-of-measure-input',
          }}
        />
        <FormItem
          component='div'
          label={
            <>
              <div className='flex items-center'>
                <span className='mb-0 mr-1 text-base'>
                  {intl.get(
                    'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.BENEFITS.VALUE_OF_UNIT_TITLE'
                  )}
                </span>
                <Tooltip
                  trigger={
                    <IconButton
                      name='help-circle'
                      description='help tooltip'
                      className='text-primary-darker'
                      data-testid='value-of-unit-tooltip'
                    />
                  }
                  openMode='hover2'
                  position='topRight'
                  timeout={100}
                  contentProps={{ className: 'py-1 pl-2 pr-1 text-sm' }}
                >
                  <div
                    onClick={openLink}
                    className='flex items-center cursor-pointer'
                  >
                    <Typography
                      variant='body2'
                      className='text-neutral-white underline'
                    >
                      {intl.get(
                        'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.BENEFITS.VALUE_OF_UNIT_TOOLTIP'
                      )}
                    </Typography>
                    <Icon
                      src={OpenLink}
                      className='text-neutral-white text-3xl'
                    />
                  </div>
                </Tooltip>
              </div>
              <span className='text-neutral-darker mt-0.5 block text-sm font-normal mb-4'>
                {intl.get(
                  'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.BENEFITS.VALUE_OF_UNIT_SUBTITLE'
                )}
              </span>
            </>
          }
          labelProps={{ className: 'mb-0', for: 'value-of-unit' }}
        >
          <div className='flex items-start'>
            <Typography className='mt-2'>{intl.get('DOLLAR_SIGN')}</Typography>
            <NumericInput
              id='value-of-unit'
              placeholder={intl.get(
                'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.BENEFITS.VALUE_OF_UNIT_PLACEHOLDER'
              )}
              divProps={{
                className: 'h-10.5 w-full ml-1',
              }}
              containerClassName='w-full'
              style={{ height: '42px' }}
              value={monetaryBenefitsData?.valueOfOneUnitOfMeasure ?? ''}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                updateBenefits(
                  'valueOfOneUnitOfMeasure',
                  event.target.value !== '' ? Number(event.target.value) : null
                );
              }}
              allowNegativeValue={false}
              min={0}
              getIsInputValid={(isValid) => {
                setNumericFieldsState((prev) => {
                  prev[0] = isValid;
                  return [...prev];
                });
              }}
              max={NUMERICAL_FIELD_MAX_VALUE}
              data-testid='value-of-unit-input'
            />
          </div>
        </FormItem>
        <FormItem
          component='div'
          label={
            <LargeLabel
              label={intl.get(
                'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.BENEFITS.CHANGE_OF_PERFORMANCE_TITLE'
              )}
              subtitle={intl.get(
                'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.BENEFITS.CHANGE_OF_PERFORMANCE_SUBTITLE'
              )}
              subtitleProps={{ className: 'text-neutral-darker' }}
              labelProps={{ className: 'text-base mb-0.5' }}
            />
          }
          labelProps={{ className: 'mb-0' }}
        >
          <NumericInput
            placeholder={intl.get(
              'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.BENEFITS.CHANGE_OF_PERFORMANCE_PLACEHOLDER'
            )}
            divProps={{
              className: 'h-10.5',
            }}
            style={{ height: '42px' }}
            value={monetaryBenefitsData?.changeInPerformancePerMonth ?? ''}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              updateBenefits(
                'changeInPerformancePerMonth',
                event.target.value !== '' ? Number(event.target.value) : null
              );
            }}
            allowNegativeValue={false}
            getIsInputValid={(isValid) => {
              setNumericFieldsState((prev) => {
                prev[1] = isValid;
                return [...prev];
              });
            }}
            min={0}
            max={NUMERICAL_FIELD_MAX_VALUE}
            data-testid='change-of-performance-input'
          />
        </FormItem>
      </div>
      <Divider className='my-8' />
      <LargeLabel
        label={intl.get(
          'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.BENEFITS.ANNUAL_MONETARY_BENEFITS_TITLE'
        )}
        subtitle={intl.get(
          'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.BENEFITS.ANNUAL_MONETARY_BENEFITS_SUBTITLE'
        )}
        subtitleProps={{ className: 'text-neutral-darker' }}
        labelProps={{ className: 'text-base mb-0.5' }}
      />

      {isNumber(annualBenefit) ? (
        <Typography>
          {intl.get('DOLLAR_SIGN')}
          {annualBenefit.toLocaleString('en-US')}
        </Typography>
      ) : (
        <Typography className='text-neutral'>
          {intl.get(
            'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.BENEFITS.ANNUAL_MONETARY_BENEFITS_EMPTY_STATE'
          )}
        </Typography>
      )}
    </div>
  );
};

export default MonetaryBenefits;
