import classNames from 'classnames';
import { Tag } from '@getsynapse/design-system';
import { GOAL_PERIODS } from 'utils/constants/strategyGoals';

type GoalTimePeriodTagProps = {
  type: typeof GOAL_PERIODS[keyof typeof GOAL_PERIODS];
  year: number;
  className?: string;
};

const GoalTimePeriodTag = ({
  type,
  year,
  className,
}: GoalTimePeriodTagProps) => {
  const isAnnualGoal = type.startsWith(GOAL_PERIODS.FISCAL_YEAR);

  return (
    <Tag
      label={`${type} ${year}`}
      className={classNames(
        `${isAnnualGoal ? 'bg-secondary-lightest' : 'bg-purple-lightest'}`,
        className
      )}
      textClassName={classNames(
        `${isAnnualGoal ? 'text-secondary-darker' : 'text-purple-darker'}`,
        'font-semibold'
      )}
    />
  );
};

export default GoalTimePeriodTag;
