import {
  createAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import get from 'lodash/get';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import { RootState } from 'state/store';
import {
  AllUsersType,
  Design,
  LDUser,
  NewProject,
  NewProjectCollaborator,
  NewProjectParticipant,
  objKeyAsString,
  Program,
  Project,
  ProjectCollaborator,
  ProjectContent,
  ProjectOwner,
  ProjectParticipant,
  Request,
  ResourceSummary,
  Status,
  Task,
  TasksSummary,
  UserAvatars,
  V2ProjectParticipant,
} from 'utils/customTypes';
import {
  allActiveUsers,
  selectActiveLDUsers,
} from 'state/UsersManagement/usersManagementSlice';
import { selectUserId, selectUserRole } from 'state/User/userSlice';
import {
  addCollaboratorAllocations,
  addParticipantAllocations,
} from 'state/ResourceAllocation/resourceAllocation';
import {
  defaultNewProjectData,
  projectUpdatedAt,
} from '../../Pages/NewProjectPage/helpers/types';
import ProjectAPI from './projectAPI';
import InsightsProjectAPI from '../Insights/ProjectsAPI';
import {
  NEW_PROJECT_FORM_FIELDS,
  PROJECT_CONTENT_TYPE,
  PROJECT_OWNER,
  PROJECT_PARTICIPANT_TYPE,
  PROJECT_STATUS,
  SLICE_STATUS,
  USER_ROLES,
  USER_STATUS,
} from 'utils/constants';
import { getContentFileName } from 'Pages/ProjectPage/tabs/Content/components/ContentTable/utils';
import { getUserDisplayName, getUserInitials } from 'utils/functions';
import { ProjectTemplateField } from 'utils/types/templates';
import type { LearningTeam } from 'utils/types/learningTeam';
import type { BusinessTeam } from 'utils/types/businessTeams';
import type { TaskMetrics } from 'utils/types/taskMetrics';
import type { ProjectResourceMetrics } from 'utils/types/projectResourceMetrics';

type UPDATE_RESULT = 'success' | 'fail';
interface ProjectState {
  value: NewProject;
  sidePanelProjectId: string;
  contentFiles: ProjectContent[];
  status: Status;
  updateResult?: UPDATE_RESULT;
  resourceSummary: objKeyAsString;
  tasksSummary: objKeyAsString;
  contentPageStatus: Status;
  taskMetrics: TaskMetrics;
  resourcesMetrics: ProjectResourceMetrics;
}

/* ============================= INITIAL STATE ============================== */
const initialState: ProjectState = {
  value: defaultNewProjectData,
  sidePanelProjectId: '',
  contentFiles: [],
  status: SLICE_STATUS.IDLE,
  resourceSummary: {},
  tasksSummary: {},
  contentPageStatus: SLICE_STATUS.IDLE,
  taskMetrics: {
    completed: 0,
    new: 0,
    inProgress: 0,
    total: 0,
    overdue: 0,
    completedPercentage: 0,
    overdueAgeInDays: 0.0,
    actualHours: 0,
    estimateHours: 0,
    onHold: 0,
  },
  resourcesMetrics: {
    estimatedHoursUnassigned: 0,
    estimatedHoursRemaining: 0,
    uniqueProjectParticipants: 0,
    totalAvailability: 0,
    averageUtilization: 0,
  },
};

const projectAPI = ProjectAPI;
const insightsProjectAPI = InsightsProjectAPI;

const updateContentFilesLinkedTasksHelper = (
  fileId: string,
  linkedTasks: Task[],
  contentFiles: ProjectContent[]
) => {
  const contentFileToUpdateIndex = contentFiles.findIndex(
    (contentFile: ProjectContent) => contentFile.id === fileId
  );
  if (contentFileToUpdateIndex === -1) {
    return contentFiles;
  }
  return [
    ...contentFiles.slice(0, contentFileToUpdateIndex),
    {
      ...contentFiles[contentFileToUpdateIndex],
      linkedTasks,
      linkedTasksIds: linkedTasks.map((task: Task) => task.id),
    },
    ...contentFiles.slice(contentFileToUpdateIndex + 1),
  ];
};

/* ================================ ACTIONS ================================= */
export const resetProject = createAction('project/RESET_PROJECT');

export const removeProjectParticipant = createAction<string>(
  'project/REMOVE_PARTICIPANT'
);

export const removeProjectCollaborator = createAction<string>(
  'project/REMOVE_COLLABORATOR'
);

export const addProjectFilesFromTask = createAction<ProjectContent[]>(
  'project/ADD_PROJECT_FILES_FROM_TASK'
);

export const setProjectId = createAction<string>('project/SET_PROJECT_ID');

export const resetUpdateStatus = createAction('project/RESET_UPDATE_STATUS');

export const updateContentFilesLinkedTasks = createAction<{
  fileId: string;
  linkedTasks: Task[];
}>('project/UPDATE_CONTENT_FILES_LINKED_TASKS');

/* ============================== REDUX THUNK =============================== */
export const fetchProject = createAsyncThunk(
  'project/FETCH_PROJECT',
  async (projectId: string) => {
    const response = await projectAPI.fetchProject(projectId);
    return response.data;
  }
);

export const fetchProjectFiles = createAsyncThunk(
  'project/FETCH_PROJECT_FILES',
  async (projectId: string) => {
    return await projectAPI.fetchProjectFiles(projectId);
  }
);

export const fetchAllocationSummary = createAsyncThunk(
  'project/FETCH_RESOURCE_SUMMARY',
  async (projectId: string) => {
    const response = await projectAPI.fetchAllocationSummary(projectId);
    return response.data;
  }
);

export const fetchTasksSummary = createAsyncThunk(
  'project/FETCH_TASKS_SUMMARY',
  async (projectId: string) => {
    const response = await projectAPI.fetchTasksSummary(projectId);
    return response.data;
  }
);

export const updateProject = createAsyncThunk(
  'project/UPDATE_PROJECT',
  async (updateData: { projectId: string; data: NewProject | any }) => {
    let fieldsToUpdate = { ...updateData.data };
    let updatedStatus = null;
    let updatedOwners = null;
    let updatedProjectRequests = null;
    let updatedProjectPrograms = null;
    if (has(fieldsToUpdate, NEW_PROJECT_FORM_FIELDS.STATUS)) {
      const { status, ...dataWithoutStatus } = fieldsToUpdate;
      updatedStatus = status;
      fieldsToUpdate = { ...dataWithoutStatus };
    }
    if (has(fieldsToUpdate, NEW_PROJECT_FORM_FIELDS.PROJECT_OWNERS)) {
      const { owners, ...dataWithoutOwners } = fieldsToUpdate;
      updatedOwners = owners;
      fieldsToUpdate = { ...dataWithoutOwners };
    }
    if (has(fieldsToUpdate, NEW_PROJECT_FORM_FIELDS.PROJECT_REQUESTS)) {
      const { projectRequests, ...dataWithoutRequests } = fieldsToUpdate;
      updatedProjectRequests = projectRequests;
      fieldsToUpdate = { ...dataWithoutRequests };
    }
    if (has(fieldsToUpdate, NEW_PROJECT_FORM_FIELDS.PROGRAMS)) {
      const { programs, ...dataWithoutPrograms } = fieldsToUpdate;
      updatedProjectPrograms = programs;
      fieldsToUpdate = { ...dataWithoutPrograms };
    }
    let updatedProject = fieldsToUpdate;
    if (!isEmpty(fieldsToUpdate)) {
      updatedProject = await projectAPI.updateProject(
        updateData.projectId,
        fieldsToUpdate
      );
    }
    if (updatedProjectRequests !== null) {
      const { projectRequests } = await projectAPI.updateProjectLinkedRequests(
        updateData.projectId,
        updatedProjectRequests
      );
      updatedProject[NEW_PROJECT_FORM_FIELDS.PROJECT_REQUESTS] =
        projectRequests;
    }
    if (updatedProjectPrograms !== null) {
      updatedProject[NEW_PROJECT_FORM_FIELDS.PROGRAMS] =
        await projectAPI.updateProjectPrograms(
          updateData.projectId,
          updatedProjectPrograms
        );
    }
    if (updatedStatus !== null) {
      await projectAPI.setProjectStatus(updateData.projectId, {
        status: updatedStatus,
      });
      updatedProject[NEW_PROJECT_FORM_FIELDS.STATUS] = updatedStatus;
    }
    if (updatedOwners !== null) {
      const { owners } = await projectAPI.setProjectOwners(
        updateData.projectId,
        updatedOwners
      );
      updatedProject[NEW_PROJECT_FORM_FIELDS.PROJECT_OWNERS] = owners;
    }
    return { ...updatedProject };
  }
);

export const reorderProjectTasks = createAsyncThunk(
  'project/REORDER_PROJECT_TASKS',
  async (updateData: { projectId: string; taskIds: string[] }) => {
    const updatedProject = await projectAPI.reorderProjectTasks(
      updateData.projectId,
      updateData.taskIds
    );
    return { ...updatedProject };
  }
);

export const addProjectFiles = createAsyncThunk(
  'project/ADD_FILES',
  async (updateData: {
    projectId: string;
    data: { projectFiles: ProjectContent[] };
  }) => {
    return await projectAPI.addProjectFiles(
      updateData.projectId,
      updateData.data
    );
  }
);

export const deleteProjectFiles = createAsyncThunk(
  'project/DELETE_FILES',
  async (updateData: { projectId: string; data: { fileId: string } }) => {
    await projectAPI.deleteProjectFiles(updateData.projectId, updateData.data);
    return updateData.data.fileId;
  }
);

export const updateProjectFileLinkedTasks = createAsyncThunk(
  'project/UPDATE_FILE_LINKED_TASKS',
  async (params: {
    projectId: string;
    data: { linkedTasksIds: string[]; fileId: string };
  }) => {
    const response = await projectAPI.updateProjectFileLinkedTasks(
      params.projectId,
      {
        linkedTasksIds: params.data.linkedTasksIds,
        fileId: params.data.fileId,
      }
    );
    const linkedTasks = get(response, 'data.data.linkedTasks', []);
    return {
      fileId: params.data.fileId,
      linkedTasks,
    };
  }
);

export const updateProjectFile = createAsyncThunk(
  'project/UPDATE_PROJECT_FILE',
  async (params: { projectId: string; data: ProjectContent }) => {
    return await projectAPI.updateProjectFile(params.projectId, params.data);
  }
);

export const addProjectParticipant = createAsyncThunk(
  'project/ADD_PARTICIPANT',
  async (
    params: {
      newParticipant: NewProjectParticipant;
      projectId: string;
    },
    { dispatch }
  ) => {
    const response = await projectAPI.addProjectParticipant(
      params.projectId,
      params.newParticipant
    );
    dispatch(addParticipantAllocations(response));
    const userId = params.newParticipant.userId;
    const newParticipant: ProjectParticipant = {
      data: {
        email: get(response, `${userId}.data.data.email`),
        lastName: get(response, `${userId}.data.data.lastName`),
        firstName: get(response, `${userId}.data.data.firstName`),
      },
      project_participants: {
        userId: get(response, `${userId}.data.id`),
        projectLearnOpId: params.projectId,
      },
    };
    return newParticipant;
  }
);

export const addProjectCollaborator = createAsyncThunk(
  'project/ADD_COLLABORATOR',
  async (
    params: {
      projectId: string;
      newCollaborator: NewProjectCollaborator;
    },
    { dispatch }
  ) => {
    const response = await projectAPI.addProjectCollaborator(
      params.projectId,
      params.newCollaborator
    );
    dispatch(
      addCollaboratorAllocations({
        ...response.collaborator,
        collaborator: response.user,
      })
    );
    return {
      data: {
        email: get(response, 'user.data.email'),
        lastName: get(response, 'user.data.lastName'),
        firstName: get(response, 'user.data.firstName'),
      },
      project_collaborators: {
        projectLearnOpId: params.projectId,
        userId: get(response, 'collaborator.userId'),
      },
    };
  }
);

export const updateArchiveStatus = createAsyncThunk(
  'project/ARCHIVE_PROJECT',
  async ({
    projectId,
    updateFields,
  }: {
    projectId: string;
    updateFields: { is_archived: boolean; status?: string };
  }) => {
    return await projectAPI.updateArchiveStatus(projectId, updateFields);
  }
);

export const fetchTaskMetricsForProject = createAsyncThunk(
  'project/FETCH_TASK_METRICS_FOR_PROJECT',
  async (projectId: string) => {
    const response = await insightsProjectAPI.fetchTaskMetricsForProject(
      projectId
    );
    return response.data;
  }
);

export const fetchResourcesMetricsForProject = createAsyncThunk(
  'project/FETCH_RESOURCES_METRICS_FOR_PROJECT',
  async (projectId: string) => {
    const response = await insightsProjectAPI.fetchResourcesMetricsForProject(
      projectId
    );
    return response.data;
  }
);

/* ================================= REDUCER ================================ */
const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProject.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchProject.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(fetchProject.fulfilled, (state, action) => {
        state.value = action.payload;
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(fetchAllocationSummary.fulfilled, (state, action) => {
        state.resourceSummary = action.payload;
      })
      .addCase(fetchTasksSummary.fulfilled, (state, action) => {
        state.tasksSummary = action.payload;
      })
      .addCase(fetchProjectFiles.pending, (state) => {
        state.contentPageStatus = SLICE_STATUS.LOADING;
      })
      .addCase(fetchProjectFiles.fulfilled, (state, action) => {
        state.contentFiles = action.payload;
        state.contentPageStatus = SLICE_STATUS.IDLE;
      })
      .addCase(updateProject.pending, (state) => {
        state.updateResult = undefined;
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(updateProject.fulfilled, (state, action) => {
        state.value = { ...state.value, ...action.payload };
        state.updateResult = 'success';
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(updateProject.rejected, (state) => {
        state.updateResult = 'fail';
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(resetUpdateStatus, (state) => {
        state.updateResult = undefined;
      })
      .addCase(reorderProjectTasks.fulfilled, (state, action) => {
        state.value = { ...state.value, ...action.payload };
      })
      .addCase(addProjectFiles.fulfilled, (state, action) => {
        state.contentFiles = state.contentFiles.concat(action.payload);
      })
      .addCase(addProjectFilesFromTask, (state, action) => {
        state.contentFiles = state.contentFiles.concat(action.payload);
      })
      .addCase(deleteProjectFiles.fulfilled, (state, action) => {
        state.contentFiles = state.contentFiles.filter(
          (projectContent: ProjectContent) =>
            projectContent.id !== action.payload
        );
      })
      .addCase(updateProjectFileLinkedTasks.fulfilled, (state, action) => {
        const { fileId, linkedTasks } = action.payload;
        const contentFileToUpdateIndex = state.contentFiles.findIndex(
          (contentFile: ProjectContent) => contentFile.id === fileId
        );
        let updatedProjectContentFiles = [...state.contentFiles];
        if (contentFileToUpdateIndex !== -1) {
          updatedProjectContentFiles = [
            ...updatedProjectContentFiles.slice(0, contentFileToUpdateIndex),
            {
              ...updatedProjectContentFiles[contentFileToUpdateIndex],
              linkedTasks,
            },
            ...updatedProjectContentFiles.slice(contentFileToUpdateIndex + 1),
          ];
        }
        state.contentFiles = updatedProjectContentFiles;
      })
      .addCase(updateProjectFile.fulfilled, (state, action) => {
        const { id: fileId } = action.payload;
        const contentFileToUpdateIndex = state.contentFiles.findIndex(
          (contentFile: ProjectContent) => contentFile.id === fileId
        );
        state.contentFiles = [
          ...state.contentFiles.slice(0, contentFileToUpdateIndex),
          { ...action.payload },
          ...state.contentFiles.slice(contentFileToUpdateIndex + 1),
        ];
      })
      .addCase(resetProject, (state) => {
        state.value = defaultNewProjectData;
      })
      .addCase(addProjectParticipant.fulfilled, (state, action) => {
        state.value = {
          ...state.value,
          participants: state.value.participants?.concat(action.payload),
        };
      })
      .addCase(removeProjectParticipant, (state, action) => {
        state.value = {
          ...state.value,
          participants: state.value.participants?.filter(
            (participant: ProjectParticipant) =>
              participant.project_participants.userId !== action.payload
          ),
        };
      })
      .addCase(addProjectCollaborator.fulfilled, (state, action) => {
        state.value = {
          ...state.value,
          collaborators: state.value.collaborators?.concat(action.payload),
        };
      })
      .addCase(removeProjectCollaborator, (state, action) => {
        state.value = {
          ...state.value,
          collaborators: state.value.collaborators?.filter(
            (collaborator: ProjectCollaborator) =>
              collaborator.project_collaborators.userId !== action.payload
          ),
        };
      })
      .addCase(updateArchiveStatus.fulfilled, (state, action) => {
        state.value = {
          ...state.value,
          ...action.payload,
        };
      })
      .addCase(setProjectId, (state, action) => {
        state.sidePanelProjectId = action.payload;
      })
      .addCase(fetchTaskMetricsForProject.fulfilled, (state, action) => {
        state.taskMetrics = action.payload;
      })
      .addCase(fetchResourcesMetricsForProject.fulfilled, (state, action) => {
        state.resourcesMetrics = action.payload;
      })
      .addCase(updateContentFilesLinkedTasks, (state, action) => {
        if (!action.payload) {
          return;
        }
        const contentFiles = [...state.contentFiles];
        const updatedContentFiles = updateContentFilesLinkedTasksHelper(
          action.payload.fileId,
          action.payload.linkedTasks,
          contentFiles
        );
        state.contentFiles = updatedContentFiles;
      });
  },
});

/* =============================== SELECTORS ================================ */
export const getProjectId = (state: RootState) =>
  state.project.sidePanelProjectId;

export const getOriginalProjectData = (state: RootState) => state.project.value;

export const getProjectSliceStatus = (state: RootState) => state.project.status;

export const getProjectUpdateResult = (state: RootState) =>
  state.project.updateResult;

export const getProjectContentFiles = (state: RootState) =>
  state.project.contentFiles;

export const getContentPageStatus = (state: RootState) =>
  state.project.contentPageStatus;

export const getCurrentProjectId = createSelector(
  [getOriginalProjectData],
  (project: NewProject) => project.id
);

export const getProjectDataAttribute =
  (attribute: keyof Project) => (state: RootState) =>
    state.project.value[attribute];

export const getProjectFiles = createSelector(
  [getProjectContentFiles],
  (contentFiles: ProjectContent[]) => {
    return contentFiles.map((file: ProjectContent) => {
      let updatedFile = { ...file };
      updatedFile.name = getContentFileName(file);
      updatedFile.linkedTasksIds = updatedFile.linkedTasks?.map(
        (task: Task) => task.id
      );
      return updatedFile;
    });
  }
);

export const getProjectLinkedDesigns = createSelector(
  [getProjectContentFiles],
  (contentFiles: ProjectContent[]) =>
    contentFiles
      .filter(
        (contentFile: ProjectContent) =>
          contentFile.content_type === PROJECT_CONTENT_TYPE.DESIGN
      )
      .map((contentFile: ProjectContent) => (contentFile.data as Design).id)
);

export const getCurrentProjectData = createSelector(
  [getOriginalProjectData],
  (project: NewProject) => {
    const projectObj = {
      ...defaultNewProjectData,
      ...projectUpdatedAt,
    } as NewProject;
    const DATE_FIELDS = [
      NEW_PROJECT_FORM_FIELDS.START_DATE,
      NEW_PROJECT_FORM_FIELDS.TARGET_COMPLETION_DATE,
      NEW_PROJECT_FORM_FIELDS.TARGET_LAUNCH_DATE,
      NEW_PROJECT_FORM_FIELDS.ACTUAL_COMPLETION_DATE,
    ];
    for (const [key, value] of Object.entries(projectObj)) {
      if (key === NEW_PROJECT_FORM_FIELDS.PROJECT_REQUESTS) {
        projectObj[key] = get(project, key, value).map(
          (request: Request) => request.id
        );
      } else if (key === NEW_PROJECT_FORM_FIELDS.PROJECT_OWNERS) {
        projectObj[key] = get(project, key, value).map(
          (owner: ProjectOwner) => owner.project_owners.userId
        );
      } else if (key === NEW_PROJECT_FORM_FIELDS.TEAMS) {
        projectObj[key] = get(project, 'ldteams', value).map(
          (team: LearningTeam) => team.id
        );
      } else if (key === NEW_PROJECT_FORM_FIELDS.BUSINESS_TEAMS) {
        projectObj[key] = get(project, 'businessTeams', value).map(
          (businessTeam: BusinessTeam) => businessTeam.id
        );
      } else if (key === NEW_PROJECT_FORM_FIELDS.PROGRAMS) {
        projectObj[key] = get(project, 'programs', value).map(
          (program: Program) => program.id
        );
      } else if (DATE_FIELDS.includes(key)) {
        const baseDate = get(project, key, value);
        if (baseDate) {
          projectObj[key] = new Date(baseDate).toISOString();
        }
      } else {
        projectObj[key] = get(project, key, value);
      }
    }
    projectObj.id = get(project, 'id');
    projectObj.projectNumber = get(project, 'projectNumber');
    projectObj.customProperties = get(project, 'customProperties', []);
    projectObj.customProperties.forEach(
      (customProperty: ProjectTemplateField) => {
        projectObj[customProperty.field_template.name] =
          customProperty.field?.value;
      }
    );
    return projectObj;
  }
);

export const getProjectOwners = createSelector(
  [getOriginalProjectData],
  (project: NewProject) =>
    project.owners.map((owner) => get(owner, 'project_owners.userId'))
);

export const getProjectMembers = createSelector(
  [getOriginalProjectData],
  (project: NewProject) =>
    project.participants?.map((participant: ProjectParticipant) =>
      get(participant, 'project_participants.userId')
    )
);

export const getProjectCollaborators = createSelector(
  [getOriginalProjectData],
  (project: NewProject) =>
    project.collaborators?.map((collaborator: ProjectCollaborator) =>
      get(collaborator, 'project_collaborators.userId')
    )
);

const getV2ProjectParticipants = createSelector(
  [getOriginalProjectData],
  (project: NewProject) => {
    return (
      project.v2_participants?.map((participant: V2ProjectParticipant) =>
        get(participant, 'v2_project_participant.user_id')
      ) || []
    );
  }
);

export const getMentionableProjectParticipants = createSelector(
  [
    getProjectOwners,
    getProjectMembers,
    getProjectCollaborators,
    getV2ProjectParticipants,
    allActiveUsers,
  ],
  (
    owners: string[],
    members: string[] = [],
    collaborators: string[] = [],
    v2Participants: string[] = [],
    users: AllUsersType[]
  ) => {
    return users
      .filter((user: AllUsersType) => {
        if (
          owners.includes(user.id) ||
          members.includes(user.id) ||
          collaborators.includes(user.id) ||
          v2Participants.includes(user.id)
        ) {
          return !(
            user.status === USER_STATUS.INVITED && user.notified_user !== true
          );
        }
        return false;
      })
      .map((user: AllUsersType) => ({
        label: getUserDisplayName(user),
        avatar: {
          imageSrc: user.avatar_url,
          initial: getUserInitials(user),
          name: getUserDisplayName(user),
        },
        value: user.id,
      })) as UserAvatars[];
  }
);

export const getCurrentUserParticipantType = createSelector(
  [getProjectOwners, getProjectMembers, getProjectCollaborators, selectUserId],
  (
    owners: string[],
    members: string[] = [],
    collaborators: string[] = [],
    currentUserId: string = ''
  ) => {
    if (owners.length === 0) {
      return undefined;
    }

    const isCurrentUserOwner = owners.find(
      (ownerId: string) => ownerId === currentUserId
    );
    if (isCurrentUserOwner) {
      return PROJECT_PARTICIPANT_TYPE.OWNER;
    }

    const isCurrentUserMember = members.find(
      (memberId: string) => memberId === currentUserId
    );
    if (isCurrentUserMember) {
      return PROJECT_PARTICIPANT_TYPE.MEMBER;
    }

    const isCurrentUserCollaborator = collaborators.find(
      (collaboratorId: string) => collaboratorId === currentUserId
    );
    if (isCurrentUserCollaborator) {
      return PROJECT_PARTICIPANT_TYPE.COLLABORATOR;
    }

    return PROJECT_PARTICIPANT_TYPE.NOT_PARTICIPANT;
  }
);

export const isCurrentUserTakingPartInProject = createSelector(
  [selectUserId, getV2ProjectParticipants],
  (currentUserId: string = '', participants: string[] = []) =>
    participants?.includes(currentUserId)
);

export const getAvailableLDUsersForOwnersAndMembers = createSelector(
  [
    getProjectOwners,
    getProjectMembers,
    getProjectCollaborators,
    selectActiveLDUsers,
    selectUserId,
    selectUserRole,
  ],
  (
    owners: string[],
    members: string[] = [],
    collaborators: string[] = [],
    ldUsers: LDUser[],
    currentUserId: string = '',
    currentUserRole: string = ''
  ) =>
    ldUsers
      .filter(
        (user: LDUser) =>
          (!owners.includes(user.id) &&
            !members.includes(user.id) &&
            !collaborators.includes(user.id) &&
            user.id !== currentUserId) ||
          currentUserRole === USER_ROLES.ADMIN
      )
      .map((user: LDUser) => ({
        label: getUserDisplayName(user),
        avatar: {
          imageSrc: user.avatar_url,
          initial: getUserInitials(user),
          name: getUserDisplayName(user),
        },
        value: user.id,
      })) as UserAvatars[]
);

export const getAvailableUsersForCollaborators = createSelector(
  [
    getProjectOwners,
    getProjectMembers,
    getProjectCollaborators,
    allActiveUsers,
    selectUserId,
    selectUserRole,
  ],
  (
    owners: string[],
    members: string[] = [],
    collaborators: string[] = [],
    users: AllUsersType[],
    currentUserId: string = '',
    currentUserRole: string = ''
  ) =>
    users
      .filter(
        (user: AllUsersType) =>
          (!owners.includes(user.id) &&
            !members.includes(user.id) &&
            !collaborators.includes(user.id) &&
            user.id !== currentUserId) ||
          currentUserRole === USER_ROLES.ADMIN
      )
      .map((user: AllUsersType) => ({
        label: getUserDisplayName(user),
        avatar: {
          imageSrc: user.avatar_url,
          initial: getUserInitials(user),
          name: getUserDisplayName(user),
        },
        value: user.id,
      })) as UserAvatars[]
);

export const getResourceSummary = createSelector(
  [(state: RootState) => state],
  (state) => {
    const summaryData: ResourceSummary = {
      items: [],
      total: {
        totalNumberOfResource: 0,
        totalHours: 0,
        totalActualHours: 0,
        totalCollaboratorsResourceNumber: 0,
        totalCollaboratorsAllocatedHours: 0,
      },
    };
    for (const [key, value] of Object.entries(state.project.resourceSummary)) {
      summaryData.items.push({
        projectRole: key,
        numberOfResource: value.resourceNumber,
        totalHours: value.totalAllocatedHour,
        totalActualHours: value.totalActualHours,
        collaboratorsResourceNumber: value.collaboratorsResourceNumber,
        collaboratorsAllocatedHours: value.collaboratorsAllocatedHours,
      });
      summaryData.total.totalNumberOfResource += value.resourceNumber;
      summaryData.total.totalHours += value.totalAllocatedHour;
      summaryData.total.totalActualHours += value.totalActualHours;
      summaryData.total.totalCollaboratorsResourceNumber +=
        value.collaboratorsResourceNumber;
      summaryData.total.totalCollaboratorsAllocatedHours +=
        value.collaboratorsAllocatedHours;
    }
    summaryData.items.forEach((item, i) => {
      if (item.projectRole === PROJECT_OWNER) {
        summaryData.items.splice(i, 1);
        summaryData.items.unshift(item);
      }
    });
    return summaryData;
  }
);

export const getTasksSummary = createSelector(
  [(state: RootState) => state],
  (state) => {
    const summaryData: TasksSummary = {
      items: [],
      total: {
        totalNumberOfTasks: 0,
        totalNumberOfResources: 0,
        totalEstimatedHours: 0,
        totalActualHours: 0,
      },
    };
    for (const [key, value] of Object.entries(state.project.tasksSummary)) {
      summaryData.items.push({
        taskType: key,
        numberOfTasks: value.numberOfTasks,
        numberOfResources: value.resourceNumber,
        totalEstimatedHours: value.totalEstimatedHours,
        totalActualHours: value.totalActualHours,
      });
      summaryData.total.totalNumberOfTasks += value.numberOfTasks;
      summaryData.total.totalNumberOfResources += value.resourceNumber;
      summaryData.total.totalEstimatedHours += value.totalEstimatedHours;
      summaryData.total.totalActualHours += value.totalActualHours;
    }

    return summaryData;
  }
);

export const getProjectTaskInsights = (state: RootState) =>
  state.project.taskMetrics;

export const getProjectResourcesInsights = (state: RootState) =>
  state.project.resourcesMetrics;

export const getIsCurrentUserProjectOwner = createSelector(
  [getProjectOwners, selectUserId],
  (owners: string[], currentUserId: string = '') =>
    owners.includes(currentUserId)
);

export const getIsProjectCanceled = (state: RootState) =>
  state.project.value.status === PROJECT_STATUS.CANCELED;

export const getIsProjectClosed = (state: RootState) =>
  state.project.value.status === PROJECT_STATUS.CLOSED;

export const getIsProjectArchived = (state: RootState) =>
  state.project.value.is_archived || false;

export default projectSlice.reducer;
