import {
  useRef,
  useState,
  useEffect,
  useMemo,
  createRef,
  RefObject,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import debounce from 'lodash/debounce';
import {
  Dropdown,
  tailwindOverride,
  Tag,
  Tooltip,
  FormItem,
  Checkbox,
  Button,
} from '@getsynapse/design-system';
import {
  fetchGoalsForDropdown,
  selectGoalsForDropdown,
  selectGoalsForDropdownOffset,
  selectIsGoalsForDropdownExhausted,
  selectProjectGoals,
  linkGoalsToProject,
  fetchProjectGoals,
} from 'state/ProjectGoals/ProjectGoalsSlice';
import { Option } from 'utils/customTypes';
import GoalTimePeriodTag from 'Pages/StrategyGoalsPage/components/GoalTimePeriodTag';
import { GOAL_TYPES } from 'utils/constants/strategyGoals';
import { showNotification as showSnackbarNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';
import { SERVER_ERROR_CODES } from 'utils/constants';
import { GoalTeam } from 'utils/types/strategyGoals';

export type GoalsDropdownProps = {
  projectId: string;
  onClose: () => void;
};

const GoalsDropdown = ({ projectId, onClose }: GoalsDropdownProps) => {
  const dispatch = useDispatch();

  const GOAL_TYPE_VALUE = 'type';

  const [searchValue, setSearchValue] = useState<string>('');
  const [originalSelectedOptions, setOriginalSelectedOptions] = useState<
    Option[]
  >([]);
  const [allSelectedOptions, setAllSelectedOptions] = useState<Option[]>([]);
  const [truncatedItems, setTruncatedItems] = useState<boolean[]>([]);
  const [dropdownClicks, setDropdownClicks] = useState(0);
  const [updateTruncatedList, setUpdateTruncatedList] = useState(false);

  const goalsRefs = useRef<RefObject<HTMLLIElement>[]>([]);

  const goals = useSelector(selectGoalsForDropdown);
  const projectGoals = useSelector(selectProjectGoals);
  const dropdownOffset = useSelector(selectGoalsForDropdownOffset);
  const isDropdownListExhausted = useSelector(
    selectIsGoalsForDropdownExhausted
  );

  const dropdownOptions = useMemo(() => {
    const groups = new Set();
    const result: Option[] = [];
    let typeIndex = 0;
    goals.forEach((goal) => {
      let groupLabel;
      if (goal.type === GOAL_TYPES.COMPANY) {
        groupLabel = intl.get('STRATEGY_GOALS.COMPANY_GOAL');
      } else if (goal.teams.length === 0 && goal.type === GOAL_TYPES.TEAM) {
        groupLabel = intl.get('STRATEGY_GOALS.FORMER_TEAM');
      } else {
        groupLabel = (goal.teams[0] as GoalTeam).name;
      }
      if (!groups.has(groupLabel)) {
        result.push({
          value: `${GOAL_TYPE_VALUE}${typeIndex}`,
          label: groupLabel,
        });
        typeIndex++;
        groups.add(groupLabel);
      }
      result.push({ value: goal.id, label: goal.title });
    });

    return result;
  }, [goals]);

  const newSelcetedOptions = useMemo(() => {
    return allSelectedOptions.filter(
      (option) =>
        !originalSelectedOptions.some(
          (originalOption) => originalOption.value === option.value
        )
    );
  }, [allSelectedOptions, originalSelectedOptions]);

  useEffect(() => {
    const fetchGoals = debounce(async () => {
      await dispatch(fetchGoalsForDropdown({ projectId, search: searchValue }));
      setUpdateTruncatedList(true);
    }, 300);

    fetchGoals();

    return () => {
      fetchGoals.cancel();
    };
  }, [dispatch, projectId, searchValue]);

  useEffect(() => {
    if (dropdownClicks === 1) setTruncatedItems([]);
  }, [dropdownClicks]);

  useEffect(() => {
    goalsRefs.current = dropdownOptions.map(() => createRef());
    setTruncatedItems([]);
  }, [dropdownOptions]);

  useEffect(() => {
    if (updateTruncatedList) {
      requestAnimationFrame(() => {
        const truncationStates = goalsRefs.current.map((ref) => {
          const item = ref.current;
          return item?.scrollWidth! > item?.clientWidth!;
        });

        setTruncatedItems(truncationStates);
        setUpdateTruncatedList(false);
      });
    }
  }, [updateTruncatedList]);

  useEffect(() => {
    const selectedOptions = projectGoals.map((goal) => ({
      value: goal.id,
      label: goal.title,
    }));
    setOriginalSelectedOptions(selectedOptions);
    setAllSelectedOptions(selectedOptions);
  }, [projectGoals]);

  const getOptionTimePeriod = useCallback(
    (value: string) => {
      const goal = goals.find((goal) => goal.id === value);
      return {
        type: goal?.timePeriod.type!,
        year: goal?.timePeriod.year!,
      };
    },
    [goals]
  );

  const renderOption = useCallback(
    (
      option: Option,
      selected: boolean,
      selectOption: (option: Option) => void,
      { className, ...otherProps }
    ) => {
      const index = dropdownOptions.findIndex(
        (opt) => opt.value === option.value
      );

      const isCurrentItemTruncated = truncatedItems[index];
      const isOptionGoalType = option.value.startsWith(GOAL_TYPE_VALUE);
      const isOptionSelected = originalSelectedOptions.some(
        (selectedOption) => selectedOption.value === option.value
      );

      const getClassNames = () => {
        if (isOptionGoalType) {
          return 'text-neutral font-semibold text-xs pt-2 px-4 pb-1';
        }
        return tailwindOverride(
          className,
          selected
            ? 'hover:bg-primary focus-visible:bg-primary'
            : 'hover:bg-secondary-lightest hover:shadow-list-item-hover-green focus-visible:bg-secondary-lightest focus-visible:shadow-list-item-hover-green',
          {
            'cursor-not-allowed': isOptionSelected,
          }
        );
      };

      const handleClick = () => {
        if (!isOptionGoalType && !isOptionSelected) {
          selectOption(option);
        }
      };

      const optionItem = (
        <li
          {...otherProps}
          ref={goalsRefs.current[index]}
          className={tailwindOverride(
            'flex justify-between	group',
            getClassNames()
          )}
          onClick={handleClick}
        >
          {isOptionGoalType && option.label}
          {!isOptionGoalType && (
            <>
              <Checkbox
                disabled={isOptionSelected}
                checked={isOptionSelected || selected}
                label={option.label}
                id='goals-dropdown-checkbox'
                inputProps={{
                  className: tailwindOverride('mt-0', {
                    'group-hover:border-neutral-white group-focus-visible:border-neutral-white':
                      selected && !isOptionSelected,
                    'group-hover:border-secondary-dark group-focus-visible:border-secondary-dark':
                      !selected && !isOptionSelected,
                  }),
                }}
                className={tailwindOverride('inline-block', {
                  'group-hover:text-secondary-darker group-focus-visible:text-secondary-darker':
                    !selected && !isOptionSelected,
                  'group-hover:text-neutral-white group-focus-visible:text-neutral-white':
                    selected && !isOptionSelected,
                  truncate: isCurrentItemTruncated,
                })}
              />
              <GoalTimePeriodTag {...getOptionTimePeriod(option.value)} />
            </>
          )}
        </li>
      );
      if (isCurrentItemTruncated) {
        return (
          <Tooltip
            className='w-full'
            ariaId='dropdown-tooltip'
            openMode='hover1'
            timeout={0}
            trigger={optionItem}
            usePortal
            showPopper
            contentProps={{ className: 'z-10 text-sm' }}
          >
            {option.label}
          </Tooltip>
        );
      } else if (isOptionSelected && truncatedItems.length) {
        return (
          <Tooltip
            className='w-full'
            ariaId='dropdown-tooltip'
            openMode='hover1'
            timeout={0}
            trigger={optionItem}
            usePortal
            showPopper
            contentProps={{ className: 'z-10 text-sm' }}
          >
            {intl.get('PROJECT_DETAIL.PROJECT_GOALS.ALREADY_LINKED_TOOLTIP')}
          </Tooltip>
        );
      } else {
        return optionItem;
      }
    },
    [
      dropdownOptions,
      truncatedItems,
      originalSelectedOptions,
      getOptionTimePeriod,
    ]
  );

  const loadMoreOptions = useCallback(async () => {
    if (!isDropdownListExhausted) {
      await dispatch(
        fetchGoalsForDropdown({
          projectId,
          search: searchValue,
          offset: dropdownOffset,
        })
      );
      setUpdateTruncatedList(true);
    }
  }, [
    dispatch,
    projectId,
    dropdownOffset,
    isDropdownListExhausted,
    searchValue,
  ]);

  const handleDropdownClick = () => {
    if (dropdownClicks === 0) {
      setUpdateTruncatedList(true);
    }
    setDropdownClicks((prevState) => prevState + 1);
  };

  const handleLinkGoalsToProject = async () => {
    const goalIds = newSelcetedOptions.map((option) => option.value);
    try {
      await dispatch(linkGoalsToProject({ projectId, goalIds }));
      dispatch(
        showSnackbarNotification({
          notificationVariant: 'success',
          notificationTitle:
            goalIds.length === 1
              ? intl.get(
                  'PROJECT_DETAIL.PROJECT_GOALS.SINGLE_LINK_SUCCESS_MESSAGE'
                )
              : intl.get(
                  'PROJECT_DETAIL.PROJECT_GOALS.MULTIPLE_LINK_SUCCESS_MESSAGE',
                  { numberOfGoals: goalIds.length }
                ),
        })
      );
      dispatch(fetchProjectGoals(projectId));
    } catch (error) {
      if (error instanceof Error) {
        const errorCode = Number(error.message);
        let notificationTitle, notificationMessage;
        switch (errorCode) {
          case SERVER_ERROR_CODES.FORBIDDEN:
            notificationTitle = intl.get(
              'PROJECT_DETAIL.PROJECT_GOALS.ERROR.FORBIDDEN_TITLE'
            );
            notificationMessage = intl.get(
              'PROJECT_DETAIL.PROJECT_GOALS.ERROR.FORBIDDEN_MESSAGE'
            );
            break;
          case SERVER_ERROR_CODES.CONFLICT:
            notificationTitle = intl.get(
              'PROJECT_DETAIL.PROJECT_GOALS.ERROR.CONFLICT_TITLE'
            );
            notificationMessage = intl.get(
              'PROJECT_DETAIL.PROJECT_GOALS.ERROR.CONFLICT_MESSAGE'
            );
            break;
          default:
            notificationTitle = intl.get(
              'PROJECT_DETAIL.PROJECT_GOALS.ERROR.LINK_ERROR_TITLE'
            );
            notificationMessage = intl.get(
              'PROJECT_DETAIL.PROJECT_GOALS.ERROR.LINK_ERROR_MESSAGE'
            );
            break;
        }
        dispatch(
          showSnackbarNotification({
            notificationVariant: 'error',
            notificationTitle,
            notificationMessage,
            autoHide: false,
          })
        );
      }
    } finally {
      onClose();
    }
  };

  return (
    <div className='flex w-full items-end'>
      <FormItem label='Goal' className='w-45% mr-6'>
        <Dropdown
          values={newSelcetedOptions}
          onClick={handleDropdownClick}
          onChange={(options) => setAllSelectedOptions(options)}
          options={dropdownOptions}
          filterable
          multiple
          triggerProps={{
            placeholder: intl.get(
              'PROJECT_DETAIL.PROJECT_GOALS.GOALS_DROPDOWN_PLACEHOLDER'
            ),
            'data-testid': 'goals-dropdown-trigger',
          }}
          listProps={{
            'data-testid': 'goals-dropdown-list',
          }}
          renderOption={renderOption}
          onFilterChange={(value) => setSearchValue(value)}
          renderSelectedOptionTag={(
            { textClassName, ...restOfProps },
            option: any
          ) => {
            const isOptionSelected = originalSelectedOptions.some(
              (selectedOption) => selectedOption.value === option.value
            );
            return (
              !isOptionSelected && (
                <Tag
                  key={option.value}
                  textClassName='text-secondary-dark'
                  {...restOfProps}
                />
              )
            );
          }}
          enableLazyLoading
          loadMoreOptions={loadMoreOptions}
          onClose={() => {
            if (searchValue) setSearchValue('');
            setDropdownClicks(0);
          }}
          filterOptions={() => dropdownOptions}
        />
      </FormItem>
      <div className='flex'>
        <Button
          className='mr-2 h-10'
          disabled={newSelcetedOptions.length === 0}
          onClick={handleLinkGoalsToProject}
          data-testid='link-goals-button'
        >
          {intl.get('LINK')}
        </Button>
        <Button
          className='h-10'
          onClick={onClose}
          variant='tertiary'
          data-testid='cancel-link-goals-button'
        >
          {intl.get('CANCEL')}
        </Button>
      </div>
    </div>
  );
};

export default GoalsDropdown;
