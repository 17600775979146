import intl from 'react-intl-universal';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  Icon,
  Typography,
  Tag,
  Avatar,
  FormItem,
} from '@getsynapse/design-system';
import { GoalOwner, GoalTeam, StrategyGoal } from 'utils/types/strategyGoals';
import { GOAL_TYPES } from 'utils/constants/strategyGoals';
import userAvatar from 'assets/icons/user-avatar.svg';
import { PATHS, USER_STATUS } from 'utils/constants';
import GoalTimePeriodTag from '../GoalTimePeriodTag';

export type GoalCardProps = {
  goal: StrategyGoal;
};

const GoalCard = ({ goal }: GoalCardProps) => {
  const goalOwner = goal.owners[0] as GoalOwner;
  const isGoalOwnerDisabled =
    goalOwner?.status === USER_STATUS.REGISTERED_DISABLED ||
    goalOwner?.status === USER_STATUS.INVITED_DISABLED;

  const isOwnerUserDeleted = goal.owners.length === 0;

  const goalType = useMemo(() => {
    if (goal.type === GOAL_TYPES.TEAM) {
      if (goal.teams.length) {
        return (goal.teams[0] as GoalTeam).name;
      } else {
        return intl.get('STRATEGY_GOALS.FORMER_TEAM');
      }
    } else {
      return intl.get('STRATEGY_GOALS.COMPANY_GOAL');
    }
  }, [goal]);

  return (
    <Link
      className='border border-primary-lighter-two rounded p-4 flex group'
      to={`${PATHS.STRATEGY_GOAL_DETAILS}/${goal.id}`}
      data-testid='goal-card'
    >
      <div className='flex justify-between w-full'>
        <div className='w-3/4'>
          <div className='flex'>
            <Typography className='group-hover:text-purple-darker transition duration-100 ease-linear'>
              {goal.title}
            </Typography>
            <div className='pt-1 pl-1'>
              <Icon
                name='arrow-forward'
                className='text-xl text-neutral-white group-hover:text-purple-darker transition duration-100 ease-linear'
              />
            </div>
          </div>
          <div className='flex mt-1'>
            <Tag
              label={goalType}
              className='bg-primary-lighter-two mr-2'
              textClassName='text-primary-darker font-semibold'
            />
            <GoalTimePeriodTag
              type={goal.timePeriod.type}
              year={goal.timePeriod.year}
              className='mr-2'
            />
          </div>
        </div>
        <FormItem label={intl.get('STRATEGY_GOALS.OWNER')} className='mr-8'>
          <Avatar
            initial={!isOwnerUserDeleted ? goalOwner.initials : undefined}
            size='small'
            imageSrc={!isOwnerUserDeleted ? goalOwner.avatarUrl! : userAvatar}
            name={
              !isOwnerUserDeleted
                ? goalOwner.name
                : intl.get('STRATEGY_GOALS.FORMER_USER')
            }
            disabled={!isOwnerUserDeleted && isGoalOwnerDisabled}
            showTooltip
            className={
              isOwnerUserDeleted ? 'border-neutral bg-neutral' : undefined
            }
            iconProps={
              isOwnerUserDeleted ? { className: 'w-6 h-6' } : undefined
            }
            data-testid='goal-card_owner-avatar'
          />
        </FormItem>
      </div>
    </Link>
  );
};

export default GoalCard;
