import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from 'state/store';
import { NewTask } from 'types/store/newTask';
import { createNewTask } from 'api/newTask';
import { deleteTask } from 'api/taskDetail';
import { reorderTasks } from 'api/tasksList';
import { SnackBarNotificationParams } from 'Hooks/useSnackbarNotification';

interface ProjectTasksListActionsState {
  taskIdToEdit?: string;
  taskIdToDuplicate?: string;
  shouldRefreshTasks?: boolean;
  snackBarNotification?: SnackBarNotificationParams | null;
  selectAllTasks?: boolean;
  selectedTasks?: string[];
  excludedTasks?: string[];
  openFiltersSidePanel?: boolean;
}

interface CreateNewTaskParams {
  newTasks: NewTask[];
  userId: string;
  sendNotifications?: boolean;
}

const initialState: ProjectTasksListActionsState = {};

export const createProjectTask = createAsyncThunk(
  'projectTasksList/createProjectTask',
  async (
    { newTasks, sendNotifications = true }: CreateNewTaskParams,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await createNewTask({
        newTasks,
        sendNotifications,
      });
      dispatch(setShouldRefreshTasks(true));
      return response.successes[0];
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue(error);
    }
  }
);

export const deleteProjectTask = createAsyncThunk(
  'projectTasksList/deleteProjectTask',
  async (taskId: string, { rejectWithValue }) => {
    try {
      const response = await deleteTask(taskId);
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue(error);
    }
  }
);

interface UpdateProjectTaskOrderParams {
  projectId: string;
  taskId: string;
  newOrder: number;
}

export const updateProjectTaskOrder = createAsyncThunk(
  'projectTasksList/updateProjectTaskOrder',
  async (params: UpdateProjectTaskOrderParams, { rejectWithValue }) => {
    try {
      await reorderTasks(params);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue('Error reordering tasks');
    }
  }
);

const actionsSlice = createSlice({
  name: 'projectTasksList/actions',
  initialState,
  reducers: {
    setTaskIdToEdit(state, action) {
      state.taskIdToEdit = action.payload;
    },
    setTaskIdToDuplicate(state, action) {
      state.taskIdToDuplicate = action.payload;
    },
    setShouldRefreshTasks(state, action) {
      state.shouldRefreshTasks = action.payload;
    },
    setSnackBarNotification(state, action) {
      state.snackBarNotification = action.payload;
    },
    toggleFiltersSidePanel(state) {
      state.openFiltersSidePanel = !state.openFiltersSidePanel;
    },
    setAllTasksSelected: (state, action) => {
      const selected = action.payload;
      state.selectAllTasks = selected;
      if (selected) {
        state.selectedTasks = [];
        state.excludedTasks = [];
      } else {
        state.selectedTasks = [];
      }
    },
    setSelectedRows: (state, action) => {
      const taskId = action.payload.id;
      const selected = action.payload.selected;
      const allTasksSelected = state.selectAllTasks;
      if (allTasksSelected) {
        const excludedTasks = new Set(state.excludedTasks);
        if (selected) {
          excludedTasks.delete(taskId);
        } else {
          excludedTasks.add(taskId);
        }
        state.excludedTasks = Array.from(excludedTasks);
      } else {
        const selectedTasks = new Set(state.selectedTasks);
        if (selected) {
          selectedTasks.add(taskId);
        } else {
          selectedTasks.delete(taskId);
        }
        state.selectedTasks = Array.from(selectedTasks);
      }
    },
    resetSelectTasks: (state) => {
      state.selectAllTasks = false;
      state.selectedTasks = [];
      state.excludedTasks = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createProjectTask.rejected, (state, action) => {
        throw new Error(action.payload as string);
      })
      .addCase(deleteProjectTask.rejected, (state, action) => {
        throw new Error(action.payload as string);
      })
      .addCase(updateProjectTaskOrder.rejected, (state, action) => {
        throw new Error(action.payload as string);
      });
  },
});

const selectAllProjectTasksListActions = (state: RootState) =>
  state.projectTasksList.actions;

export const selectTaskIdToEdit = createSelector(
  [selectAllProjectTasksListActions],
  (actions: ProjectTasksListActionsState) => actions.taskIdToEdit
);

export const selectTaskIdToDuplicate = createSelector(
  [selectAllProjectTasksListActions],
  (actions: ProjectTasksListActionsState) => actions.taskIdToDuplicate
);

export const selectShouldRefreshTasks = createSelector(
  [selectAllProjectTasksListActions],
  (actions: ProjectTasksListActionsState) => actions.shouldRefreshTasks ?? false
);

export const selectSnackBarNotification = createSelector(
  [selectAllProjectTasksListActions],
  (actions: ProjectTasksListActionsState) =>
    actions.snackBarNotification ?? null
);

export const selectAllTasksSelected = (state: RootState) =>
  state.projectTasksList.actions.selectAllTasks ?? false;

export const selectExcludedTasks = (state: RootState) =>
  state.projectTasksList.actions.excludedTasks ?? [];

export const selectSelectedTasks = (state: RootState) =>
  state.projectTasksList.actions.selectedTasks ?? [];

export const selectExportEnabled = createSelector(
  [selectAllTasksSelected, selectExcludedTasks, selectSelectedTasks],
  (
    allTasksSelected: boolean,
    excludedTasks: string[],
    selectedTasks: string[]
  ) => {
    return (
      allTasksSelected || excludedTasks.length > 0 || selectedTasks.length > 0
    );
  }
);

export const selectIsFiltersSidePanelOpen = (state: RootState) =>
  state.projectTasksList.actions.openFiltersSidePanel ?? false;

export const {
  setTaskIdToEdit,
  setTaskIdToDuplicate,
  setShouldRefreshTasks,
  setSnackBarNotification,
  setAllTasksSelected,
  setSelectedRows,
  resetSelectTasks,
  toggleFiltersSidePanel,
} = actionsSlice.actions;

export default actionsSlice.reducer;
