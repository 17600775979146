import intl from 'react-intl-universal';
import { FormItem, Dropdown, Typography } from '@getsynapse/design-system';
import { timePeriodOptions } from 'utils/constants/strategyGoals';
import { TimePeriodOption, TimePeriodType } from 'utils/types/strategyGoals';
import { Option } from 'utils/customTypes';

type TimePeriodProps = {
  value?: Option[];
  isRequired?: boolean;
  onChange: (value: string | number | TimePeriodType, prop: string) => void;
  newGoal?: boolean;
};

const TimePeriod = ({
  value,
  onChange,
  isRequired = true,
  newGoal = false,
}: TimePeriodProps) => {
  const changeHandler = (option: TimePeriodOption) => {
    if (!newGoal) {
      const timePeriod = {
        type: option.value.timePeriod,
        year: Number(option.value.year),
      };
      onChange(timePeriod, 'timePeriod');
    } else {
      onChange(option.value.timePeriod, 'timePeriod');
      onChange(Number(option.value.year), 'year');
    }
  };

  return (
    <FormItem
      label={intl.get('STRATEGY_GOALS.ADD_GOAL_MODAL.TIME_PERIOD')}
      labelProps={{
        required: isRequired,
        'data-cy': 'time-period_label',
      }}
    >
      {isRequired && (
        <Dropdown
          options={timePeriodOptions}
          values={value}
          onChange={changeHandler}
          triggerProps={{
            placeholder: intl.get(
              'STRATEGY_GOALS.ADD_GOAL_MODAL.TIME_PERIOD_PLACEHOLDER'
            ),
            'data-testid': 'time_period_dropdown',
          }}
          listProps={{
            'data-testid': 'time_period_list',
          }}
        />
      )}
      {!isRequired && value?.length && (
        <Typography variant='body' className='pb-4' data-cy='time-period_value'>
          {`${value[0].value.timePeriod} ${value[0].value.year}`}
        </Typography>
      )}
    </FormItem>
  );
};

export default TimePeriod;
