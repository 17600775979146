import { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppThunkDispatch } from 'state/store';
import {
  toggleFiltersSidePanel,
  selectIsFiltersSidePanelOpen,
} from 'state/ProjectTasksList/actions/actionsSlice';
import { TaskListFilters } from 'types/store/tasksList';
import TasksFiltersSidePanel from 'Pages/TasksListPage/components/v2/TasksFiltersSidePanel/TasksFiltersSidePanel';

interface TableFiltersProps {
  filters: TaskListFilters;
  onUpdateFilters: (filters: TaskListFilters) => void;
}

const TableFilters: FC<TableFiltersProps> = ({ filters, onUpdateFilters }) => {
  const dispatch = useAppThunkDispatch();
  const shouldDisplayFilterComponent = useSelector(
    selectIsFiltersSidePanelOpen
  );

  const toggleFilterComponent = useCallback(() => {
    dispatch(toggleFiltersSidePanel());
  }, [dispatch]);

  return (
    <TasksFiltersSidePanel
      open={shouldDisplayFilterComponent}
      onClose={toggleFilterComponent}
      filters={filters}
      onUpdateFilters={onUpdateFilters}
      isCentralizedTasksPage={false}
    />
  );
};

export default TableFilters;
