import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState, ThunkApi } from 'state/store';
import { Status } from 'utils/customTypes';
import { SLICE_STATUS } from 'utils/constants';
import { UserSummary } from 'types/store/normalized';

/* ============================== STATE SETUP =============================== */
interface UserSummariesState {
  status: Status;
}

const initialState: UserSummariesState = {
  status: SLICE_STATUS.IDLE,
};

const adapter = createEntityAdapter<UserSummary>();

/* ============================== REDUX THUNK =============================== */
function concurrencyCondition(
  thunkParameters: unknown,
  { getState }: { getState: () => RootState }
): boolean {
  const { status } = getState().normalized.userSummaries;
  return status !== SLICE_STATUS.LOADING;
}

interface GetListParams {
  userIds?: string[];
}

interface GetUserSummariesResponse {
  data: UserSummary[];
  totalCount: number;
  links: { next: string };
  cursor: string;
}

const NO_OP_RESPONSE: GetUserSummariesResponse = {
  data: [],
  totalCount: 0,
  cursor: '',
  links: { next: '' },
};

export const getUserSummaries = createAsyncThunk<
  GetUserSummariesResponse,
  GetListParams,
  ThunkApi
>(
  'normalized/userSummary/getUserSummaries',
  async ({ userIds = [] }, { getState, extra: { getUserSummariesApi } }) => {
    const state = getState();
    const uniqueUsers = new Set(userIds);
    const notCached = Array.from(uniqueUsers).filter(
      (id) => !state.normalized.userSummaries.entities[id]
    );

    if (notCached.length === 0) {
      return NO_OP_RESPONSE;
    }

    const { data, meta, links } = await getUserSummariesApi().getList({
      params: {
        userIds: notCached,
      },
    });
    return { data, totalCount: meta.totalCount, cursor: meta.cursor, links };
  },
  { condition: concurrencyCondition }
);

/* ================================= REDUCER ================================ */
const userSummariesSlice = createSlice({
  name: 'normalized/userSummary',
  initialState: adapter.getInitialState(initialState),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserSummaries.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(getUserSummaries.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(getUserSummaries.fulfilled, (state, action) => {
        adapter.upsertMany(state, action.payload.data);

        state.status = SLICE_STATUS.SUCCESS;
      });
  },
});

/* =============================== SELECTORS ================================ */
const defaultSelectors = adapter.getSelectors<RootState>(
  (state) => state.normalized.userSummaries
);

const selectStatus = (state: RootState) =>
  state.normalized.userSummaries.status;

const selectUserSummaryById = (state: RootState) => (id: string) =>
  defaultSelectors.selectById(state, id);

const selectUserSummariesByIds = (state: RootState) => (ids: string[]) =>
  ids
    .map((id) => defaultSelectors.selectById(state, id))
    .filter((summary): summary is UserSummary => summary !== undefined);

export const userSummarySelectors = {
  ...defaultSelectors,
  selectStatus,
  selectUserSummaryById,
  selectUserSummariesByIds,
};

export default userSummariesSlice.reducer;
