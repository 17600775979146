import { useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import {
  Typography,
  Tag,
  IconButton,
  Icon,
  Tooltip,
  Modal,
} from '@getsynapse/design-system';
import { PATHS, SERVER_ERROR_CODES } from 'utils/constants';
import { GOAL_TYPES } from 'utils/constants/strategyGoals';
import { GoalTeam, StrategyGoal } from 'utils/types/strategyGoals';
import unlinkIcon from 'assets/icons/unlink.svg';
import newTabIcon from 'assets/icons/open.svg';
import GoalTimePeriodTag from 'Pages/StrategyGoalsPage/components/GoalTimePeriodTag';
import {
  fetchProjectGoals,
  unlinkGoalsFromProject,
} from 'state/ProjectGoals/ProjectGoalsSlice';
import { showNotification as showSnackbarNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';

type GoalCardProps = {
  goal: Omit<StrategyGoal, 'owners'>;
  isReadOnly: boolean;
};

const GoalCard = ({ goal, isReadOnly }: GoalCardProps) => {
  const dispatch = useDispatch();

  const { projectId } = useParams<{ projectId: string }>();

  const [isUnlinkModalOpen, setIsUnlinkModalOpen] = useState(false);

  const goalType = useMemo(() => {
    if (goal.type === GOAL_TYPES.TEAM) {
      if (goal.teams!.length) {
        return (goal.teams![0] as GoalTeam).name;
      } else {
        return intl.get('STRATEGY_GOALS.FORMER_TEAM');
      }
    } else {
      return intl.get('STRATEGY_GOALS.COMPANY_GOAL');
    }
  }, [goal]);

  const handleUnlinkGoalFromProject = async () => {
    try {
      await dispatch(unlinkGoalsFromProject({ projectId, goalId: goal.id }));
      dispatch(
        showSnackbarNotification({
          notificationVariant: 'success',
          notificationTitle: intl.get(
            'PROJECT_DETAIL.PROJECT_GOALS.UNLINK_SUCCESS_MESSAGE'
          ),
        })
      );
      dispatch(fetchProjectGoals(projectId));
    } catch (error) {
      closeModal();
      if (error instanceof Error) {
        const errorCode = Number(error.message);
        let notificationTitle, notificationMessage;

        switch (errorCode) {
          case SERVER_ERROR_CODES.FORBIDDEN:
            notificationTitle = intl.get(
              'PROJECT_DETAIL.PROJECT_GOALS.ERROR.FORBIDDEN_TITLE'
            );
            notificationMessage = intl.get(
              'PROJECT_DETAIL.PROJECT_GOALS.ERROR.FORBIDDEN_MESSAGE'
            );
            break;
          case SERVER_ERROR_CODES.NOT_FOUND:
            notificationTitle = intl.get(
              'PROJECT_DETAIL.PROJECT_GOALS.ERROR.NOT_FOUND_TITLE'
            );
            notificationMessage = intl.get(
              'PROJECT_DETAIL.PROJECT_GOALS.ERROR.FORBIDDEN_MESSAGE'
            );
            break;

          default:
            notificationTitle = intl.get(
              'PROJECT_DETAIL.PROJECT_GOALS.ERROR.UNLINK_ERROR_TITLE'
            );
            notificationMessage = intl.get(
              'PROJECT_DETAIL.PROJECT_GOALS.ERROR.LINK_ERROR_MESSAGE'
            );
            break;
        }

        dispatch(
          showSnackbarNotification({
            notificationVariant: 'error',
            notificationTitle,
            notificationMessage,
            autoHide: false,
          })
        );
      }
    }
  };

  const closeModal = () => {
    if (isUnlinkModalOpen) {
      setIsUnlinkModalOpen(false);
    }
  };

  return (
    <div
      className='border rounded border-primary-lighter-two py-4 px-6 flex justify-between'
      data-testid='goal-card'
    >
      <Modal
        title={intl.get('PROJECT_DETAIL.PROJECT_GOALS.UNLINK_GOAL_MODAL_TITLE')}
        titleIcon={{ src: unlinkIcon }}
        isOpen={isUnlinkModalOpen}
        aria-label={intl.get(
          'PROJECT_DETAIL.PROJECT_GOALS.UNLINK_GOAL_MODAL_TITLE'
        )}
        closeModal={closeModal}
        actionButtons={[
          {
            children: intl.get('UNLINK'),
            color: 'warning',
            onClick: handleUnlinkGoalFromProject,
          },
          {
            children: intl.get('CANCEL'),
            'data-testid': 'cancel-unlink-button',
            variant: 'tertiary',
            onClick: closeModal,
          },
        ]}
      />
      <Link
        to={`${PATHS.STRATEGY_GOAL_DETAILS}/${goal.id}`}
        className='group'
        target='_blank'
        rel='noopener noreferrer'
      >
        <div className='flex mr-8 w-vw-37rem items-center'>
          <Typography className='truncate group-hover:text-purple-darker transition duration-100 ease-linear'>
            {goal.title}
          </Typography>
          <div className='pl-1 h-6'>
            <Icon
              src={newTabIcon}
              className='text-xl text-neutral-white group-hover:text-purple-darker transition duration-100 ease-linear'
            />
          </div>
        </div>
      </Link>
      <div className='flex items-center'>
        <Tag
          label={goalType}
          className='bg-primary-lighter-two mr-2'
          textClassName='text-primary-darker font-semibold'
        />
        <GoalTimePeriodTag
          type={goal.timePeriod!.type}
          year={goal.timePeriod!.year}
        />
        {!isReadOnly && (
          <Tooltip
            className='ml-6'
            showPopper
            trigger={
              <IconButton
                src={unlinkIcon}
                description={intl.get(
                  'PROJECT_DETAIL.PROJECT_GOALS.UNLINK_BUTTON_TOOLTIP'
                )}
                hasASize={false}
                data-testid={`goal-unlink-button-${goal.id}`}
                onClick={() => setIsUnlinkModalOpen(true)}
              />
            }
            openMode='hover2'
            timeout={0}
          >
            {intl.get('PROJECT_DETAIL.PROJECT_GOALS.UNLINK_BUTTON_TOOLTIP')}
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default GoalCard;
