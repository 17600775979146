import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit';
import intl from 'react-intl-universal';
import { Option, Status } from 'utils/customTypes';
import { SLICE_STATUS, USER_STATUS } from 'utils/constants';
import { GOAL_TYPES } from 'utils/constants/strategyGoals';
import { RootState } from 'state/store';
import singleStrategyGoalAPI from './SingleStrategyGoalAPI';
import { GoalOwner, StrategyGoal, GoalTeam } from 'utils/types/strategyGoals';

interface SingleStrategyGoal extends StrategyGoal {
  permissions: {
    canEdit: boolean;
    canDelete: boolean;
  };
}

interface StrategyGoalDetailState {
  status: Status;
  value: SingleStrategyGoal;
  errorCode: number;
}

/* ============================= INITIAL STATE ============================== */

const initialState: StrategyGoalDetailState = {
  value: {
    id: '',
    title: '',
    type: '',
    timePeriod: {
      type: '',
      year: 2025,
    },
    owners: [],
    teams: [
      {
        id: '',
        name: '',
      },
    ],
    permissions: {
      canEdit: false,
      canDelete: false,
    },
  },
  errorCode: 0,
  status: SLICE_STATUS.IDLE,
};

/* ============================= ACTIONS ============================== */
export const resetGoal = createAction('singleStrategyGoal/RESET_GOAL_DETAILS');

/* ============================== REDUX THUNK =============================== */
export const fetchStrategyGoalDetail = createAsyncThunk(
  'singleStrategyGoal/FETCH_STRATEGY_GOAL_DETAIL',
  async (goalId: string, { rejectWithValue }) => {
    try {
      const response = await singleStrategyGoalAPI.fetchStrategyGoalDetail(
        goalId
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteStrategyGoal = createAsyncThunk(
  'singleStrategyGoal/DELETE_STRATEGY_GOAL',
  async (goalId: string) => {
    const response = await singleStrategyGoalAPI.deleteGoal(goalId);
    return response;
  }
);

export const updateStrategyGoal = createAsyncThunk(
  'singleStrategyGoal/UPDATE_STRATEGY_GOAL',
  async ({ goalId, data }: { goalId: string; data: Partial<StrategyGoal> }) => {
    const response = await singleStrategyGoalAPI.updateGoal(goalId, data);
    return response?.data;
  }
);

/* ================================= REDUCER ================================ */
const singleStrategyGoalSlice = createSlice({
  name: 'singleStrategyGoal',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStrategyGoalDetail.pending, (state) => {
        state.errorCode = 0;
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchStrategyGoalDetail.rejected, (state, action) => {
        state.errorCode = action.payload as number;
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(fetchStrategyGoalDetail.fulfilled, (state, action) => {
        state.value = action.payload;
        state.errorCode = 0;
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(resetGoal, (state) => {
        state.value = initialState.value;
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(deleteStrategyGoal.rejected, () => {
        throw new Error();
      })
      .addCase(updateStrategyGoal.fulfilled, (state, action) => {
        state.value = action.payload;
      })
      .addCase(updateStrategyGoal.rejected, (state) => {
        throw new Error();
      });
  },
});

/* =============================== SELECTORS ================================ */

export const selectStrategyGoalDetail = (state: RootState) =>
  state.singleStrategyGoal.value;

export const selectStrategyGoalDetailStatus = (state: RootState) =>
  state.singleStrategyGoal.status;

export const selectStrategyGoalDetailErrorCode = (state: RootState) =>
  state.singleStrategyGoal.errorCode;

export const selectStrategyGoalDetailOwner = (state: RootState) => {
  const owners = state.singleStrategyGoal.value.owners;
  const owner = owners[0] as GoalOwner;
  const goalDetailOwner =
    owners.length > 0
      ? [
          {
            label: owner.name,
            value: owner.id,
            avatar: {
              imageSrc: owner.avatarUrl!,
              initial: owner.initials,
              name: owner.name,
            },
            disabled:
              owner.status === USER_STATUS.REGISTERED_DISABLED ||
              owner.status === USER_STATUS.INVITED_DISABLED,
          },
        ]
      : [];
  return goalDetailOwner;
};

export const selectStrategyGoalDetailTeam = (state: RootState) => {
  const strategyGoalDetail = state.singleStrategyGoal.value;
  let goalDetailTeam: Option[] = [];

  if (!strategyGoalDetail) return goalDetailTeam;

  if (strategyGoalDetail) {
    const { teams = [], type } = strategyGoalDetail;

    if (teams.length > 0) {
      goalDetailTeam = teams.map((team) => ({
        label: (team as GoalTeam).name,
        value: (team as GoalTeam).id,
      }));
    } else {
      if (type === GOAL_TYPES.COMPANY) {
        goalDetailTeam = [
          {
            label: intl.get('STRATEGY_GOALS.COMPANY_GOAL'),
            value: GOAL_TYPES.COMPANY,
          },
        ];
      } else if (type === GOAL_TYPES.TEAM) {
        goalDetailTeam = [
          { label: intl.get('STRATEGY_GOALS.FORMER_TEAM'), value: '' },
        ];
      }
    }

    return goalDetailTeam?.length ? goalDetailTeam : [];
  }
};

export default singleStrategyGoalSlice.reducer;
