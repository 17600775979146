import { FC, useCallback } from 'react';
import intl from 'react-intl-universal';
import { useDispatch } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Typography, tailwindOverride } from '@getsynapse/design-system';
import { setTaskId } from 'state/SingleTask/singleTaskSlice';
import { setTaskIdToEdit } from 'state/ProjectTasksList/actions/actionsSlice';
import { CapacityTask } from 'state/TasksAssignmentsResourcesCapacity/types';
import { getStatusColumn } from 'Pages/ProjectsListPage/helpers/tableColumnsValues';

const TasksDetails: FC<{
  task: CapacityTask;
}> = ({ task }) => {
  const dispatch = useDispatch();
  const { newTaskListViews = false } = useFlags();

  const formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 1,
  });

  const estimatedHours = task.number_of_assignments
    ? Number(task.estimate_hours) / task.number_of_assignments
    : Number(task.estimate_hours);

  const actualHours = Number(task.actual_hours);

  const opentTaskSidePanel = useCallback(() => {
    if (newTaskListViews) {
      dispatch(setTaskIdToEdit(task.id));
    } else {
      dispatch(setTaskId(task.id));
    }
  }, [dispatch, newTaskListViews, task.id]);

  return (
    <div className='flex'>
      <div className='w-12' />
      <div className='w-3/4 flex flex-1 flex-col px-6 py-2'>
        <Typography
          variant='caption'
          className={tailwindOverride(
            'text-purple-dark cursor-pointer font-semibold',
            'focus:underline hover:underline focus:outline-none',
            'hover:text-purple-darker focus:text-purple-darker',
            'active:text-purple-darker visited:text-purple-darker'
          )}
          role='button'
          onClick={opentTaskSidePanel}
          data-testid={`allocation-task-${task.id}`}
        >
          {task.name}
        </Typography>
        <Typography variant='caption' className='text-neutral-dark'>
          {intl.get(
            'PEOPLE.RESOURCE_ALLOCATION.TABLE.ASSIGNMENT_ESTIMATED_TIME',
            {
              estimated: `${formatter.format(estimatedHours)}`,
            }
          )}
          {actualHours > 0 &&
            ` | ${intl.get(
              'PEOPLE.RESOURCE_ALLOCATION.TABLE.ASSIGNMENT_ACTUAL_TIME',
              { hours: `${formatter.format(actualHours)}` }
            )}`}
        </Typography>
      </div>
      <div className='w-1/4 pl-4'>
        {getStatusColumn(task.status, false, 'self-start')}
      </div>
    </div>
  );
};

export default TasksDetails;
