import { FC, useRef, useCallback, useEffect } from 'react';
import intl from 'react-intl-universal';
import debounce from 'lodash/debounce';
import {
  Datepicker,
  Input,
  Button,
  Icon,
  tailwindOverride,
} from '@getsynapse/design-system';
import { rangeDate } from 'utils/customTypes';
import { NewActualHoursEntry } from 'types/store/actualHours';

interface HoursFormItemProps {
  id: string;
  updateEntry: (
    entryId: string,
    key: keyof Omit<NewActualHoursEntry, 'userId'>,
    value: number | string
  ) => void;
  removeEntry: (entryId: string) => void;
  displayDeleteButton?: boolean;
}

const HoursFormItem: FC<HoursFormItemProps> = ({
  id,
  updateEntry,
  removeEntry,
  displayDeleteButton = false,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const debouncedUpdateHours = useRef(
    debounce((e: React.ChangeEvent<HTMLInputElement>) => {
      const duration = parseFloat(e.target.value) * 60;
      updateEntry(id, 'hours', duration);
    }, 300)
  ).current;

  const handleSubmittedHoursDateChange = useCallback(
    (date: rangeDate) => {
      if (!date.startDate) {
        return;
      }
      updateEntry(
        id,
        'date',
        (date.startDate as Date).toLocaleDateString('en-US')
      );
    },
    [id, updateEntry]
  );

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div className='flex w-full items-center space-x-2 relative'>
      <div
        className={tailwindOverride(
          'flex',
          'transition-all duration-500 ease-in-out'
        )}
        style={{
          width: displayDeleteButton ? 'calc(100% - 2.5rem)' : '100%',
        }}
      >
        <Input
          ref={inputRef}
          divProps={{ className: 'w-4/6' }}
          className='rounded-none rounded-l'
          placeholder={intl.get('TASK_ACTUAL_HOURS.HOURS_FIELD_PLACEHOLDER')}
          onChange={debouncedUpdateHours}
          aria-label='hours-input'
          type='number'
          step='any'
        />
        <Datepicker
          inputProps={{
            className: 'rounded-none rounded-r border-l-0 hover:border-l',
            'aria-label': 'datepicker-input',
          }}
          className='w-2/6'
          useCustomModifier={false}
          startPlaceHolder={intl.get('TASK_ACTUAL_HOURS.TODAY')}
          onPickDate={handleSubmittedHoursDateChange}
        />
      </div>
      <Button
        aria-label='delete-hours-button'
        variant='tertiary'
        onClick={() => removeEntry(id)}
        className={tailwindOverride(
          'p-1 absolute right-0',
          'transition-all duration-500 ease-in-out',
          {
            'opacity-0 -translate-x-full pointer-events-none':
              !displayDeleteButton,
            'opacity-100 translate-x-0': displayDeleteButton,
          }
        )}
      >
        <Icon name='trash' className='text-2xl text-neutral-dark' />
      </Button>
    </div>
  );
};

export default HoursFormItem;
