import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import intl from 'react-intl-universal';
import truncate from 'lodash/truncate';
import { Button } from '@getsynapse/design-system';
import PageTitle from 'Molecules/PageTitle/PageTitle';
import {
  fetchStrategyGoalDetail,
  resetGoal,
  selectStrategyGoalDetail,
  selectStrategyGoalDetailStatus,
  selectStrategyGoalDetailErrorCode,
} from 'state/SingleStrategyGoal/SingleStrategyGoalSlice';
import LoadingError from 'Molecules/LoadingError/LoadingError';
import { SLICE_STATUS } from 'utils/constants';
import DeleteGoalModal from './components/DeleteGoalModal/DeleteGoalModal';
import GoalDetails from './components/GoalDetails//GoalDetails';

const GoalDetailsPage = () => {
  const dispatch = useDispatch();

  const { goalId } = useParams<{
    goalId: string;
  }>();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useEffect(() => {
    if (goalId) {
      dispatch(fetchStrategyGoalDetail(goalId));
    }
    return () => {
      dispatch(resetGoal());
    };
  }, [dispatch, goalId]);

  const strategyGoalDetailStatus = useSelector(selectStrategyGoalDetailStatus);
  const strategyGoalDetailError = useSelector(
    selectStrategyGoalDetailErrorCode
  );
  const strategyGoalDetail = useSelector(selectStrategyGoalDetail);

  const isGoalDetailEmpty = Object.keys(strategyGoalDetail).length === 0;

  return (
    <>
      <DeleteGoalModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        goalId={goalId}
      />
      {strategyGoalDetailStatus === SLICE_STATUS.FAILED && (
        <LoadingError headerHeight='6rem' errorCode={strategyGoalDetailError} />
      )}
      {strategyGoalDetailStatus === SLICE_STATUS.IDLE && !isGoalDetailEmpty && (
        <div className='h-full flex flex-col'>
          <PageTitle
            titleComponent={truncate(strategyGoalDetail.title, {
              length: 100,
            })}
            dataCy='goal-title'
            className='leading-6'
            headerChildren={
              strategyGoalDetail.permissions.canDelete && (
                <Button
                  variant='tertiary'
                  iconName='trash'
                  data-testid='delete-goal-button'
                  onClick={() => setIsDeleteModalOpen(true)}
                >
                  {intl.get('DELETE')}
                </Button>
              )
            }
          />
          <div className='h-full px-8 pt-2.5 pb-8 w-full flex flex-row'>
            <div className='w-3/4' />
            <GoalDetails />
          </div>
        </div>
      )}
    </>
  );
};

export default GoalDetailsPage;
