import { useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import classNames from 'classnames';
import { FormItem, Dropdown, Typography } from '@getsynapse/design-system';
import {
  fetchStrategyTeams,
  selectStrategyGoalsTeams,
} from 'state/StrategyGoals/StrategyGoalsSlice';
import { isLDTeamManager, selectUserRole } from 'state/User/userSlice';
import { USER_ROLES } from 'utils/constants';
import { Option } from 'utils/customTypes';
import { GOAL_TYPES } from 'utils/constants/strategyGoals';
import { GoalTeam as GoalTeamType } from 'utils/types/strategyGoals';

type GoalTeamProps = {
  onChange: (value: string[] | string | GoalTeamType[], prop: string) => void;
  value?: Option[];
  isRequired?: boolean;
  newGoal?: boolean;
};

const GoalTeam = ({
  onChange,
  value,
  isRequired = true,
  newGoal = false,
}: GoalTeamProps) => {
  const dispatch = useDispatch();

  const teams = useSelector(selectStrategyGoalsTeams);
  const userRole = useSelector(selectUserRole);
  const isTeamManager = useSelector(isLDTeamManager);

  const selectedValue = useMemo(() => {
    if (value?.length) {
      return value;
    }
    if (userRole !== USER_ROLES.ADMIN && isTeamManager && teams?.length === 1) {
      return [teams[0]];
    }
    return [];
  }, [userRole, teams, isTeamManager, value]);

  useEffect(() => {
    dispatch(fetchStrategyTeams());
  }, [dispatch]);

  const changeHandler = useCallback(
    (option: Option) => {
      if (option.value === GOAL_TYPES.COMPANY) {
        onChange(GOAL_TYPES.COMPANY, 'type');
        if (!newGoal) {
          onChange([], 'teams');
        } else {
          onChange([], 'goalTeams');
        }
      } else {
        onChange(GOAL_TYPES.TEAM, 'type');
        if (!newGoal) {
          const team = [
            {
              id: option.value,
              name: option.label,
            },
          ];
          onChange(team, 'teams');
        } else {
          onChange([option.value], 'goalTeams');
        }
      }
    },
    [onChange, newGoal]
  );

  useEffect(() => {
    if (selectedValue.length && newGoal) {
      changeHandler(selectedValue[0]);
    }
  }, [changeHandler, selectedValue, newGoal]);

  return (
    <FormItem
      label={intl.get('STRATEGY_GOALS.ADD_GOAL_MODAL.ACCOUNTABLE_TEAM')}
      labelProps={{
        required: isRequired,
        'data-cy': 'accountable-team_label',
      }}
      className='mb-4'
    >
      {isRequired && (
        <Dropdown
          options={teams}
          values={selectedValue}
          onChange={changeHandler}
          filterable
          listProps={{
            'data-testid': 'accountable_team_list',
          }}
          triggerProps={{
            placeholder: intl.get(
              'STRATEGY_GOALS.ADD_GOAL_MODAL.ACCOUNTABLE_TEAM_PLACEHOLDER'
            ),
            'data-testid': 'accountable_team_dropdown',
          }}
        />
      )}
      {!isRequired && (
        <Typography
          variant='body'
          className={classNames({
            'text-neutral-dark': !value,
          })}
          data-cy='accountable-team_value'
        >
          {value?.length && value[0].label}
        </Typography>
      )}
    </FormItem>
  );
};

export default GoalTeam;
