import {
  createSlice,
  createEntityAdapter,
  createSelector,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from 'state/store';
import { UpdatedDependency } from 'types/store/taskDependencies';
import { SLICE_STATUS } from 'utils/constants';
import {
  fetchDependenciesUpdatePreview,
  FetchDependenciesUpdatePreviewParams,
} from 'api/taskDependenciesUpatePreview';
import { Status } from 'utils/customTypes';

const updatedSuccessorsAdapter = createEntityAdapter<UpdatedDependency>({
  selectId: (dependency) => dependency.id,
});

const updatedPredecessorsAdapter = createEntityAdapter<UpdatedDependency>({
  selectId: (dependency) => dependency.id,
});

interface DependenciesUpdateState {
  status?: Status;
  displayPreview?: boolean;
  updatedPredecessors: ReturnType<
    typeof updatedPredecessorsAdapter.getInitialState
  >;
  updatedSuccessors: ReturnType<
    typeof updatedSuccessorsAdapter.getInitialState
  >;
}

const initialState: DependenciesUpdateState = {
  updatedSuccessors: updatedSuccessorsAdapter.getInitialState(),
  updatedPredecessors: updatedPredecessorsAdapter.getInitialState(),
};

export const fetchUpdatedDependenciesPreview = createAsyncThunk(
  'task/dependenciesUpdate/fetchUpdatedDependenciesPreview',
  async (params: FetchDependenciesUpdatePreviewParams, { rejectWithValue }) => {
    try {
      const response = await fetchDependenciesUpdatePreview(params);
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue(error);
    }
  }
);

const DependenciesUpdateSlice = createSlice({
  name: 'task/dependenciesUpdate',
  initialState,
  reducers: {
    setFetchUpdatedDependenciesPreviewStatus(state, action) {
      state.status = action.payload;
    },
    setShouldDisplayPreview(state, action) {
      state.displayPreview = action.payload;
    },
    setUpdatedPredecessors(state, action) {
      updatedPredecessorsAdapter.setAll(
        state.updatedPredecessors,
        action.payload
      );
    },
    setUpdatedSuccessors(state, action) {
      updatedSuccessorsAdapter.setAll(state.updatedSuccessors, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUpdatedDependenciesPreview.pending, (state) => {
      state.status = SLICE_STATUS.LOADING;
    });
    builder.addCase(
      fetchUpdatedDependenciesPreview.fulfilled,
      (state, action) => {
        state.status = SLICE_STATUS.IDLE;
        state.updatedPredecessors = updatedPredecessorsAdapter.setAll(
          state.updatedPredecessors,
          action.payload.predecessors
        );
        state.updatedSuccessors = updatedSuccessorsAdapter.setAll(
          state.updatedSuccessors,
          action.payload.successors
        );
      }
    );
    builder.addCase(fetchUpdatedDependenciesPreview.rejected, (state) => {
      state.status = SLICE_STATUS.FAILED;
      state.displayPreview = false;
    });
  },
});

export const selectShouldDisplayPreview = (state: RootState) =>
  state.dependenciesUpdate.displayPreview || false;

export const { selectAll: selectAllUpdatedPredecessors } =
  updatedPredecessorsAdapter.getSelectors(
    (state: RootState) => state.dependenciesUpdate.updatedPredecessors
  );

export const { selectAll: selectAllUpdatedSuccessors } =
  updatedSuccessorsAdapter.getSelectors(
    (state: RootState) => state.dependenciesUpdate.updatedSuccessors
  );

export const selectFetchUpdatedDependenciesPreviewStatus = (state: RootState) =>
  state.dependenciesUpdate.status;

export const selectUpdatedDependenciesPreview = createSelector(
  [selectAllUpdatedPredecessors, selectAllUpdatedSuccessors],
  (
    updatedPredecessors: UpdatedDependency[],
    updatedSuccessors: UpdatedDependency[]
  ) => {
    const updatedDependencies = [...updatedPredecessors, ...updatedSuccessors];
    return updatedDependencies.sort(
      (a: UpdatedDependency, b: UpdatedDependency) => {
        if (a.newStartDate && b.newStartDate) {
          return (
            new Date(a.newStartDate).getTime() -
            new Date(b.newStartDate).getTime()
          );
        }
        return 0;
      }
    );
  }
);

export const {
  setShouldDisplayPreview,
  setUpdatedPredecessors,
  setUpdatedSuccessors,
  setFetchUpdatedDependenciesPreviewStatus,
} = DependenciesUpdateSlice.actions;

export default DependenciesUpdateSlice.reducer;
