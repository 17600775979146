import intl from 'react-intl-universal';
import { Icon, Typography, Tooltip } from '@getsynapse/design-system';

const DateOverlapWarning = () => {
  return (
    <Tooltip
      openMode='hover2'
      timeout={0}
      usePortal
      showPopper
      position='topCenter'
      hasMaxWidth={false}
      contentProps={{
        className: 'bg-neutral-darker z-50 px-2 py-1',
      }}
      trigger={
        <div className='flex h-full items-center'>
          <Icon
            name='alert-circle'
            aria-label='overlapping-dates-icon'
            className='text-xl text-warning-darker'
          />
        </div>
      }
    >
      <Typography variant='label' className='text-neutral-white'>
        {intl.get('TASKS.TASK_DETAIL_PAGE.DEPENDENCIES.OVERLAPPING_DATES')}
      </Typography>
    </Tooltip>
  );
};

export default DateOverlapWarning;
