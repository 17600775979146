import { useEffect, useMemo, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { FormLabel, tailwindOverride } from '@getsynapse/design-system';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  fetchTaskDetail,
  selectCanEdit,
  selectCanDelete,
  selectTaskName,
  selectTaskProject,
  selectTaskStatus,
  selectIsTaskDisabled,
  selectTaskDisplayId,
  selectTaskDueDate,
  selectTaskSliceStatus,
  selectUpdateTaskStatus,
  selectShouldRedirectAfterUpdate,
  selectCanShiftDates,
  setUpdateTaskStatus,
  setTaskDetailToUpdate,
  setShouldRedirectAfterUpdate,
  resetState,
} from 'state/TaskDetail/taskSlice';
import { fetchTaskComments } from 'state/SingleTask/singleTaskSlice';
import { setSnackBarNotification } from 'state/ProjectTasksList/actions/actionsSlice';
import { selectFetchUpdatedDependenciesPreviewStatus } from 'state/TaskDependencies/DependenciesUpdateSlice';
import { TaskNotFoundError } from 'types/store/taskDetail';
import { PROJECT_STATUS, TASK_STATUS, SLICE_STATUS } from 'utils/constants';
import useSnackbarNotification from 'Hooks/useSnackbarNotification';
import useUpdateTask from './hooks/useUpdateTask';
import useDeleteTask from './hooks/useDeleteTask';
import useNavigateToTasksList from './hooks/useNavigateToTasksList';
import { validateIfTaskDueDateIsPast } from './TaskDetailForm/helpers/helpers';
import PageTitle from 'Molecules/PageTitle/PageTitle';
import DetailsPage from 'Molecules/DetailsPage/DetailsPage';
import TaskDetailForm from './TaskDetailForm/TaskDetailForm';
import TaskCommentsPanel from './Comments/TaskCommentsPanel';
import TaskDetailFooter from './TaskDetailFooter/TaskDetailFooter';
import Loader from 'Molecules/Loader/Loader';
import LinkedContent from './LinkedContent/LinkedContent';
import DisableTaskToogle from './DisableTaskToogle/DisableTaskToogle';
import StatusBanner from './StatusBanner/StatusBanner';
import TaskActionsPopup from './TaskActionsPopup/TaskActionsPopup';
import UnsavedChangesPrompt from 'Organisms/UnsavedChangesPrompt/UnsavedChangesPrompt';
import Dependencies from './Dependencies/Dependencies';
import DependenciesUpdatePreview from './Dependencies/DependenciesUpdatePreview/DependenciesUpdatePreview';

const TasksDetailPage = () => {
  const dispatch = useDispatch();
  const {
    taskComments: taskCommentsFlag = false,
    taskDependenciesR1 = false,
    taskDependenciesR2 = false,
  } = useFlags();
  const { taskId, projectId } = useParams<{
    projectId: string;
    taskId: string;
  }>();
  const displayId = useSelector(selectTaskDisplayId);
  const taskName = useSelector(selectTaskName);
  const taskProject = useSelector(selectTaskProject);
  const taskDueDate = useSelector(selectTaskDueDate);
  const canUpdateTask = useSelector(selectCanEdit);
  const canDeleteTask = useSelector(selectCanDelete);
  const taskStatus = useSelector(selectTaskStatus);
  const isTaskCompleted = taskStatus === TASK_STATUS.COMPLETED;
  const isTaskOnHold = taskStatus === TASK_STATUS.ON_HOLD;
  const isTaskDisabled = useSelector(selectIsTaskDisabled);
  const taskSliceStatus = useSelector(selectTaskSliceStatus);
  const updateTaskRequestStatus = useSelector(selectUpdateTaskStatus);
  const canShiftDates = useSelector(selectCanShiftDates);
  const shouldRedirectAfterUpdate = useSelector(
    selectShouldRedirectAfterUpdate
  );
  const fetchDependenciesUpdatePreview = useSelector(
    selectFetchUpdatedDependenciesPreviewStatus
  );
  const [confirmedNavigation, setConfirmedNavigation] =
    useState<boolean>(false);
  const isTaskDueDatePast = useMemo(
    () => validateIfTaskDueDateIsPast(taskDueDate),
    [taskDueDate]
  );
  const canShiftDependenciesDates = taskDependenciesR2 && canShiftDates;
  const isReadOnly = [PROJECT_STATUS.CLOSED, PROJECT_STATUS.CANCELED].includes(
    taskProject?.status
  );
  const isViewOnly = isTaskOnHold || isTaskDisabled;
  const canAddActualHours = canUpdateTask && !isTaskDisabled;

  const {
    SnackbarNotification,
    snackbarProps,
    isNotificationVisible,
    showNotification,
  } = useSnackbarNotification();

  const { navigateToTasksList } = useNavigateToTasksList(projectId);

  const {
    task,
    requiredFieldsErrors,
    canSubmitChanges,
    hasAssignedUsers,
    updateTaskDetail,
    updateTaskAssignees,
    updateTaskDateRange,
    updateTaskStatus,
    handleUpdateTask,
  } = useUpdateTask({
    showNotification,
    canShiftDependenciesDates,
  });

  const { deleteTask } = useDeleteTask({
    taskId,
    showNotification,
    onSuccessCallback: navigateToTasksList,
  });

  const init = useCallback(async () => {
    try {
      await dispatch(fetchTaskDetail({ taskId }));
      dispatch(fetchTaskComments(taskId));
    } catch (error) {
      if (error instanceof TaskNotFoundError) {
        navigateToTasksList();
      }
    }
  }, [dispatch, taskId, navigateToTasksList]);

  const onUpdateTaskSuccessCallback = useCallback(() => {
    const notificationMessage = intl.get(
      'TASKS.TASK_DETAIL_PAGE.UPDATE_SUCCESSFUL',
      {
        taskName: task?.name!,
      }
    );
    if (shouldRedirectAfterUpdate) {
      dispatch(
        setSnackBarNotification({
          notificationMessage,
        })
      );
      setConfirmedNavigation(true);
    } else {
      showNotification({
        notificationTitle: '',
        notificationMessage,
      });
    }
    dispatch(setUpdateTaskStatus(undefined));
    dispatch(setTaskDetailToUpdate(undefined));
    dispatch(setShouldRedirectAfterUpdate(undefined));
  }, [dispatch, task, shouldRedirectAfterUpdate, showNotification]);

  const onUpdateTaskFailedCallback = useCallback(() => {
    showNotification({
      notificationVariant: 'error',
      notificationTitle: intl.get('TASKS.TASK_DETAIL_PAGE.UPDATE_ERROR.TITLE'),
      notificationMessage: intl.get(
        'TASKS.TASK_DETAIL_PAGE.UPDATE_ERROR.MESSAGE'
      ),
    });
    dispatch(setUpdateTaskStatus(undefined));
  }, [showNotification, dispatch]);

  useEffect(() => {
    init();
    return () => {
      dispatch(resetState());
    };
  }, [init, dispatch]);

  useEffect(() => {
    if (updateTaskRequestStatus === SLICE_STATUS.SUCCESS) {
      onUpdateTaskSuccessCallback();
    }

    if (updateTaskRequestStatus === SLICE_STATUS.FAILED) {
      onUpdateTaskFailedCallback();
    }
  }, [
    updateTaskRequestStatus,
    onUpdateTaskSuccessCallback,
    onUpdateTaskFailedCallback,
  ]);

  useEffect(() => {
    if (confirmedNavigation) {
      setConfirmedNavigation(false);
      navigateToTasksList();
    }
  }, [confirmedNavigation, navigateToTasksList]);

  if (!taskSliceStatus || taskSliceStatus === SLICE_STATUS.LOADING) {
    return <Loader />;
  }

  return (
    <div className='flex flex-col h-full'>
      {taskDependenciesR2 && <DependenciesUpdatePreview />}
      <PageTitle titleComponent={`${taskProject.title} / ${taskName}`} />
      <DetailsPage
        hasFooter={!taskDependenciesR1}
        topBar={
          <div className='z-5 flex items-center px-4'>
            <div className='w-1/2'>
              <FormLabel>{intl.get('TASKS.TASK_DETAIL_PAGE.ID')}</FormLabel>
              <span className='ml-1'>{displayId}</span>
            </div>
            <div className='w-1/2 justify-end flex items-center gap-x-2'>
              {!isTaskCompleted && (
                <DisableTaskToogle
                  taskId={taskId}
                  isTaskDisabled={task?.disabled ?? false}
                  disabled={!canUpdateTask || isReadOnly}
                />
              )}
              {canDeleteTask && (
                <TaskActionsPopup
                  disabled={isReadOnly}
                  deleteTaskCallback={deleteTask}
                />
              )}
            </div>
          </div>
        }
        content={
          <div
            className='bg-neutral-white flex-grow overflow-y-auto flex flex-row '
            data-cy='task-form-body'
          >
            <div className='w-full'>
              <StatusBanner
                isTaskDisabled={isTaskDisabled}
                isTaskOnHold={isTaskOnHold}
                isTaskDueDatePast={!isTaskCompleted && isTaskDueDatePast}
              />
              <div
                className={tailwindOverride(
                  'border-b border-neutral-lighter pb-10',
                  'flex flex-col gap-y-9'
                )}
              >
                <TaskDetailForm
                  task={task}
                  requiredFieldsErrors={requiredFieldsErrors}
                  project={taskProject!}
                  canUpdateTask={canUpdateTask}
                  canAddActualHours={canAddActualHours}
                  isReadOnly={isReadOnly}
                  isViewOnly={!canUpdateTask || isViewOnly}
                  hasAssignedUsers={hasAssignedUsers}
                  updateTaskDetail={updateTaskDetail}
                  updateTaskAssignees={updateTaskAssignees}
                  updateTaskDateRange={updateTaskDateRange}
                  updateTaskStatus={updateTaskStatus}
                />
                {taskDependenciesR1 && (
                  <TaskDetailFooter
                    taskDisabled={!canSubmitChanges}
                    onCancel={navigateToTasksList}
                    onSave={handleUpdateTask}
                    isUpdating={
                      updateTaskRequestStatus === SLICE_STATUS.UPDATING ||
                      fetchDependenciesUpdatePreview === SLICE_STATUS.LOADING
                    }
                  />
                )}
              </div>
              {taskDependenciesR1 && (
                <Dependencies
                  projectId={projectId}
                  taskId={taskId}
                  taskDisplayId={displayId}
                  canAddDependencies={
                    canUpdateTask && !isViewOnly && !taskProject.isArchived
                  }
                />
              )}
              <LinkedContent
                taskId={taskId}
                projectId={projectId}
                isReadOnly={isReadOnly}
                canUpdateContent={
                  canUpdateTask && !isViewOnly && !taskProject.isArchived
                }
                aligCtaButtonsToRight={!taskDependenciesR1}
              />
            </div>
          </div>
        }
        comments={
          taskCommentsFlag && <TaskCommentsPanel projectId={projectId} />
        }
      />
      {!taskDependenciesR1 && (
        <TaskDetailFooter
          taskDisabled={!canSubmitChanges}
          onCancel={navigateToTasksList}
          onSave={handleUpdateTask}
          isUpdating={updateTaskRequestStatus === SLICE_STATUS.UPDATING}
          positionedAtPageBottom
        />
      )}
      <UnsavedChangesPrompt
        hasChanges={canSubmitChanges && !confirmedNavigation}
      />
      {isNotificationVisible && snackbarProps && (
        <SnackbarNotification {...snackbarProps} />
      )}
    </div>
  );
};

export default TasksDetailPage;
