import { RequestsApi } from './requests';
import { UserSummariesApi } from './userSummaries';

export class BaseApiFactory {
  private static requestsApi: RequestsApi;
  private static userSummariesApi: UserSummariesApi;

  private constructor() {}

  static getRequestsApi(): RequestsApi {
    if (!this.requestsApi) {
      this.requestsApi = new RequestsApi();
    }
    return this.requestsApi;
  }

  static getUserSummariesApi(): UserSummariesApi {
    if (!this.userSummariesApi) {
      this.userSummariesApi = new UserSummariesApi();
    }
    return this.userSummariesApi;
  }
}
