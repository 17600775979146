import axios, { AxiosInstance } from 'axios';
import config from 'config/Config';
import { initAxios } from 'utils/axios';

class ProjectGoalsAPI {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(`${config.get('backendURL')}v2/`);
  }

  fetchProjectGoals = async (projectId: string) => {
    const res = await this.instance.get(`projects/${projectId}/goals`);
    return res;
  };

  fetchGoalsForDropdown = async (
    projectId: string,
    search: string,
    limit: number,
    offset: number
  ) => {
    const queryParams = new URLSearchParams({
      limit: limit.toString(),
      offset: offset.toString(),
    });
    if (search) {
      queryParams.append('search', search);
    }
    const res = await this.instance.get(
      `strategy/goals/linkable-to-project/${projectId}?${queryParams.toString()}`
    );
    return res;
  };

  linkProjectToGoals = async (projectId: string, goalIds: string[]) => {
    try {
      const res = await this.instance.post('strategy/goals/link-to-project', {
        projectId,
        goalIds,
      });
      return res;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.status.toString());
      } else {
        throw new Error(error as string);
      }
    }
  };

  unlinkGoalsFromProject = async (projectId: string, goalId: string) => {
    try {
      const res = await this.instance.post(
        'strategy/goals/unlink-from-project',
        {
          projectId,
          goalIds: [goalId],
        }
      );
      return res;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.status.toString());
      } else {
        throw new Error(error as string);
      }
    }
  };
}

export default new ProjectGoalsAPI();
