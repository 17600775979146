import { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { TaskListFilters } from 'types/store/tasksList';
import { ProjectTasksTableTab } from 'utils/customTypes';
import { useAppThunkDispatch } from 'state/store';
import {
  selectExportEnabled,
  toggleFiltersSidePanel,
  selectIsFiltersSidePanelOpen,
} from 'state/ProjectTasksList/actions/actionsSlice';
import SearchFilter from 'Pages/TasksListPage/components/v2/TasksListTable/ActionsHeader/SearchFilter';
import FilterButton from 'Organisms/TableHeaderActionButtons/FilterButton';
import AppliedFiltersTags from 'Pages/TasksListPage/components/v2/AppliedFiltersTags/AppliedFiltersTags';
import ImportExportActions from './components/ImportExportActions/ImportExportActions';

interface ActionsTableHeaderProps {
  tableName: ProjectTasksTableTab;
  filters: TaskListFilters;
  onUpdateFilters: (filters: TaskListFilters) => void;
  onSearchFilterChange: (value: string) => void;
  canFilter?: boolean;
  onExport: () => void;
}

const ActionsTableHeader: FC<ActionsTableHeaderProps> = ({
  filters,
  tableName,
  onUpdateFilters,
  onSearchFilterChange,
  canFilter = true,
  onExport,
}) => {
  const exportEnabled = useSelector(selectExportEnabled);
  const shouldDisplayFilterComponent = useSelector(
    selectIsFiltersSidePanelOpen
  );
  const dispatch = useAppThunkDispatch();

  const handleToggleFilterComponent = useCallback(
    () => dispatch(toggleFiltersSidePanel()),
    [dispatch]
  );

  return (
    <>
      <SearchFilter
        initialSearchFilterValue={filters.search || ''}
        onSearchFilterChange={onSearchFilterChange}
        tableName={tableName}
      >
        <FilterButton
          aria-label={`${tableName}__filter-button`}
          onToggleFilter={handleToggleFilterComponent}
          aria-pressed={shouldDisplayFilterComponent}
          disabled={!canFilter}
        />
        <ImportExportActions
          onExport={onExport}
          exportEnabled={exportEnabled}
        />
      </SearchFilter>
      <AppliedFiltersTags
        filters={filters}
        onUpdateFilters={onUpdateFilters}
        tableName={tableName}
      />
    </>
  );
};

export default ActionsTableHeader;
