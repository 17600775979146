import intl from 'react-intl-universal';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal, Typography } from '@getsynapse/design-system';
import { deleteStrategyGoal } from 'state/SingleStrategyGoal/SingleStrategyGoalSlice';
import { showNotification as showSnackbarNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';
import { PATHS } from 'utils/constants';

type DeleteGoalModalProps = {
  isOpen: boolean;
  onClose: () => void;
  goalId: string;
};

const DeleteGoalModal = ({ isOpen, onClose, goalId }: DeleteGoalModalProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleDeleteGoal = async () => {
    onClose();
    try {
      await dispatch(deleteStrategyGoal(goalId));
      history.push(PATHS.STRATEGY_GOALS);
      dispatch(
        showSnackbarNotification({
          notificationVariant: 'success',
          notificationTitle: intl.get(
            'STRATEGY_GOALS.DELETE_GOAL_MODAL.DELETE_SUCCESS_TITLE'
          ),
        })
      );
    } catch {
      dispatch(
        showSnackbarNotification({
          notificationVariant: 'error',
          notificationTitle: intl.get(
            'STRATEGY_GOALS.DELETE_GOAL_MODAL.DELETE_ERROR_TITLE'
          ),
          notificationMessage: intl.get(
            'STRATEGY_GOALS.DELETE_GOAL_MODAL.DELETE_ERROR_MESSAGE'
          ),
        })
      );
    }
  };

  return (
    <Modal
      title={intl.get('STRATEGY_GOALS.DELETE_GOAL_MODAL.TITLE')}
      data-testid='delete-goal-modal'
      isOpen={isOpen}
      closeModal={onClose}
      aria-label={intl.get('STRATEGY_GOALS.DELETE_GOAL_MODAL.TITLE')}
      titleIcon={{ name: 'trash', className: 'text-error-dark' }}
      actionButtons={[
        {
          children: intl.get('DELETE'),
          color: 'danger',
          onClick: handleDeleteGoal,
          'data-testid': 'confirm-delete-goal-button',
        },
        {
          children: intl.get('CANCEL'),
          variant: 'tertiary',
          onClick: onClose,
          'data-testid': 'cancel-delete-goal-button',
        },
      ]}
    >
      <Typography>
        {intl.get('STRATEGY_GOALS.DELETE_GOAL_MODAL.MESSAGE')}
      </Typography>
    </Modal>
  );
};

export default DeleteGoalModal;
