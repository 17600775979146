import { FC } from 'react';
import {
  Avatar,
  AvatarGroup,
  TableRow,
  tailwindOverride,
} from '@getsynapse/design-system';
import { getRequestRoute } from 'utils/dynamicRoutes';
import intl from 'react-intl-universal';

import { Request, UserSummary } from 'types/store/normalized';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsUserLd, selectOrganizationId } from 'state/User/userSlice';
import RequestStatusTag from 'Atoms/RequestStatusTag/RequestStatusTag';
import { formatDate, formatRequestLearningPriority } from 'utils/formatters';

import { userSummarySelectors } from 'state/normalized/userSummaries/userSummariesSlice';
import { isUserStatusDisabled } from 'utils/functions';

interface TableRowProps {
  request: Request;
  isOdd?: boolean;
}

const mapToAvatar = (user: UserSummary) => {
  const allInitials = user.name.split(' ').map((n) => n[0]);
  const initial = allInitials[0] + allInitials.splice(-1);
  return {
    imageSrc: user.avatarUrl || '',
    initial,
    name: user.name,
    disabled: isUserStatusDisabled(user.status),
  };
};

const mapToAvatarGroup = (owners: UserSummary[]) => owners.map(mapToAvatar);

const RequestTableRow: FC<TableRowProps> = ({ request, isOdd }) => {
  const history = useHistory();
  const organizationId = useSelector(selectOrganizationId)!;
  const isLearningUser = useSelector(selectIsUserLd);
  const getUserSummary = useSelector(
    userSummarySelectors.selectUserSummaryById
  );
  const getUserSummaries = useSelector(
    userSummarySelectors.selectUserSummariesByIds
  );

  const owners = getUserSummaries(request.ownerIds);
  const requester = getUserSummary(request.requesterId || '');

  return (
    <TableRow
      className={tailwindOverride(
        'cursor-pointer',
        isOdd ? 'bg-neutral-lightest-two' : 'bg-neutral-white'
      )}
      key={request.id}
      onClick={() => history.push(getRequestRoute(request.id, organizationId))}
      cells={[
        {
          content: request.number,
        },
        {
          content: (
            <div className='w-64 truncate'>
              <Link
                to={getRequestRoute(request.id, organizationId)}
                title={request.title}
              >
                {request.title}
              </Link>
            </div>
          ),
        },
        {
          content: <RequestStatusTag status={request.status} />,
        },
        ...(isLearningUser
          ? [
              {
                content: formatRequestLearningPriority(
                  request.learnAndDevelopmentPriority
                ),
              },
            ]
          : []),
        {
          content: requester && (
            <div className='flex items-center'>
              <Avatar
                {...mapToAvatar(requester)}
                deactivatedText={intl.get('DEACTIVATED')}
              />
              <span className='ml-2.5'>{requester.name}</span>
            </div>
          ),
        },
        {
          content:
            owners && owners.length === 1 ? (
              <div className='flex items-center'>
                <AvatarGroup
                  avatars={mapToAvatarGroup(owners)}
                  deactivatedText={intl.get('DEACTIVATED')}
                />
                <span className='ml-2.5'>{owners[0].name}</span>
              </div>
            ) : (
              <AvatarGroup
                avatars={mapToAvatarGroup(owners)}
                deactivatedText={intl.get('DEACTIVATED')}
              />
            ),
          className: `font-semibold`,
        },
        {
          content: formatDate(request.createdAt),
        },
        {
          content: formatDate(request.submittedAt),
        },
        {
          content: formatDate(request.decisionDate),
        },
        {
          content: formatDate(request.updatedAt),
        },
      ]}
    />
  );
};

export default RequestTableRow;
