import { FileUpload } from '@getsynapse/design-system';
import { intakeQuestionWrapper } from 'utils/customTypes';
import { useState } from 'react';
import config from 'config/Config';
import { PickerFileMetadata } from 'filestack-js';
import get from 'lodash/get';

const FileUploadQuestion = ({
  question,
  handler,
  disabled,
}: intakeQuestionWrapper) => {
  const [currentFiles, setCurrentFiles] = useState<Array<PickerFileMetadata>>(
    question.data.value || []
  );

  return (
    <FileUpload
      uploadInstructions={get(question, 'data.placeholder')}
      config={{
        ...config.get('fileStack'),
        apiKey: process.env.REACT_APP_FILESTACK_API_KEY,
        security: {
          policy: process.env.REACT_APP_FILESTACK_POLICY,
          signature: process.env.REACT_APP_FILESTACK_SIGNATURE,
        },
      }}
      onFileUploadHandle={(files: Array<PickerFileMetadata>) => {
        setCurrentFiles((prevFiles: Array<PickerFileMetadata>) => {
          const newFiles = prevFiles.concat(files);
          handler(question, newFiles, 'data.value');

          return newFiles;
        });
      }}
      onFileRemoveHandle={(handle: string) => {
        setCurrentFiles((prevFiles: Array<PickerFileMetadata>) => {
          const newFiles = prevFiles.filter(
            (file: PickerFileMetadata) => file.handle !== handle
          );
          handler(question, newFiles, 'data.value');

          return newFiles;
        });
      }}
      files={currentFiles}
      disabled={disabled}
    />
  );
};

export default FileUploadQuestion;
