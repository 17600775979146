import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppThunkDispatch } from 'state/store';
import {
  createFilterSetting,
  selectFiltersSettingsTypeId,
  updateFilterSetting,
} from 'state/Settings/Filters/FiltersSlice';
import { ProjectTasksTableTab } from 'utils/customTypes';
import { TaskListFilters } from 'types/store/tasksList';

interface UseFilterSettingsParams {
  tableName: ProjectTasksTableTab;
  updateFiltersCallback: (filters: TaskListFilters) => void;
}

interface UseFilterSettingsResponse {
  setFiltersSettings: (filters: TaskListFilters) => void;
}

const useFilterSettings: (
  params: UseFilterSettingsParams
) => UseFilterSettingsResponse = ({ tableName, updateFiltersCallback }) => {
  const dispatch = useAppThunkDispatch();
  const filtersSettingsTypeId = useSelector((state: RootState) =>
    selectFiltersSettingsTypeId(state, tableName)
  );

  const handleSetFiltersSettings = useCallback(
    (filters: TaskListFilters) => {
      const { search, ...otherFilters } = filters;
      if (filtersSettingsTypeId) {
        dispatch(
          updateFilterSetting({
            id: filtersSettingsTypeId,
            updateFields: { filter_settings: otherFilters },
          })
        );
      } else {
        dispatch(
          createFilterSetting({
            filter_type: tableName,
            filter_settings: otherFilters,
          })
        );
      }
      updateFiltersCallback(otherFilters);
    },
    [filtersSettingsTypeId, dispatch, tableName, updateFiltersCallback]
  );

  return useMemo(
    () => ({
      setFiltersSettings: handleSetFiltersSettings,
    }),
    [handleSetFiltersSettings]
  );
};

export default useFilterSettings;
