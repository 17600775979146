import { FC } from 'react';
import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { selectUserType } from 'state/User/userSlice';
import {
  getCurrentProjectId,
  getIsProjectArchived,
  getIsProjectCanceled,
  getIsProjectClosed,
} from 'state/Project/projectSlice';
import {
  selectHasTeamTasksTableFilters,
  selectHasTeamTasksTableSearchFilter,
} from 'state/ProjectTasksList/teamTasks/teamTasksSlice';
import {
  selectHasUserTasksTableFilters,
  selectHasUserTasksTableSearchFilter,
} from 'state/ProjectTasksList/userTasks/userTasksSlice';
import {
  PROJECT_USER_ACTIONS,
  USER_TYPES,
  TASKS_TABLE_TABS,
} from 'utils/constants';
import { ProjectTasksTableTab } from 'utils/customTypes';
import RenderNoRecords from 'Atoms/NoRecords/NoRecords';
import { useUserPermissionsContext } from 'Pages/ProjectPage/context/UserPermissionsContext';
import CreateTaskModal from 'Pages/TasksListPage/components/v2/CreateTaskModal/CreateTaskModal';
import EmptyTasks from 'assets/images/empty-tasks.svg';
import EmptyFilteredTasksTable from 'assets/images/empty-filtered-tasks-table.svg';
import EmptySeachTasksTable from 'assets/images/empty-search-tasks-table.svg';

const EmptyTableBody: FC<{
  tableName: ProjectTasksTableTab;
}> = ({ tableName }) => {
  const { canUser } = useUserPermissionsContext();
  const userType = useSelector(selectUserType);
  const isProjectArchived = useSelector(getIsProjectArchived);
  const isProjectClosed = useSelector(getIsProjectClosed);
  const isProjectCanceled = useSelector(getIsProjectCanceled);
  const projectId = useSelector(getCurrentProjectId);
  const hasSeachFilterSelectors =
    tableName === TASKS_TABLE_TABS.TEAM_TASKS
      ? selectHasTeamTasksTableSearchFilter
      : selectHasUserTasksTableSearchFilter;
  const hasFiltersSelectors =
    tableName === TASKS_TABLE_TABS.TEAM_TASKS
      ? selectHasTeamTasksTableFilters
      : selectHasUserTasksTableFilters;
  const hasSeachFilterApplied = useSelector(hasSeachFilterSelectors);
  const hasFiltersApplied = useSelector(hasFiltersSelectors);
  const isUserFacilitator = userType === USER_TYPES.FACILITATOR;

  const shouldDisplayAddTaskButton =
    !isProjectCanceled &&
    !isProjectClosed &&
    !isProjectArchived &&
    (canUser(PROJECT_USER_ACTIONS.ADD_TASK) || isUserFacilitator);

  if (hasSeachFilterApplied || hasFiltersApplied) {
    return (
      <RenderNoRecords
        imageSrc={
          hasSeachFilterApplied ? EmptySeachTasksTable : EmptyFilteredTasksTable
        }
        className={
          hasSeachFilterApplied
            ? 'h-empty-table-body'
            : 'h-empty-filtered-table-body'
        }
        imageAlt={
          hasSeachFilterApplied
            ? 'search tasks empty state'
            : 'filtered tasks empty state'
        }
        caption={intl.get('REQUESTS_LIST_PAGE.NO_RECORDS')}
      />
    );
  }

  return (
    <RenderNoRecords
      caption={intl.get('TASKS.TABLE.EMPTY')}
      imageSrc={EmptyTasks}
      className='h-empty-table-body w-full'
      labelClassName='mt-0'
      imageClassName='-ml-4'
    >
      {shouldDisplayAddTaskButton && (
        <div className='flex-initial self-center mt-2'>
          <CreateTaskModal
            isCentralizedPage={false}
            projectId={projectId}
            isUserFacilitator={isUserFacilitator}
            canUserAddTask={canUser(PROJECT_USER_ACTIONS.ADD_TASK)}
          />
        </div>
      )}
    </RenderNoRecords>
  );
};

export default EmptyTableBody;
