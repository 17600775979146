import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Status } from 'utils/customTypes';
import { SLICE_STATUS } from 'utils/constants';
import { StrategyGoal } from 'utils/types/strategyGoals';
import { RootState } from 'state/store';
import projectGoalsAPI from './ProjectGoalsAPI';

interface ProjectGoalsState {
  currentProjectGoals: {
    status: Status;
    list: Omit<StrategyGoal, 'owners'>[];
    totalCount: number;
  };
  goalsForDropdown: {
    status: Status;
    list: Omit<StrategyGoal, 'owners'>[];
    offset: number;
    isListExhausted: boolean;
  };
  linkingStatus: Status;
}

/* ============================= INITIAL STATE ============================== */

const initialState: ProjectGoalsState = {
  currentProjectGoals: {
    status: SLICE_STATUS.IDLE,
    list: [],
    totalCount: 0,
  },
  goalsForDropdown: {
    status: SLICE_STATUS.IDLE,
    list: [],
    offset: 0,
    isListExhausted: false,
  },
  linkingStatus: SLICE_STATUS.IDLE,
};

/* ============================= ACTIONS ============================== */

/* ============================== REDUX THUNK =============================== */
export const fetchProjectGoals = createAsyncThunk(
  'projectGoals/FETCH_PROJECT_GOALS',
  async (projectId: string) => {
    const response = await projectGoalsAPI.fetchProjectGoals(projectId);
    return response?.data;
  }
);

export const fetchGoalsForDropdown = createAsyncThunk(
  'projectGoals/FETCH_GOALS_FOR_DROPDOWN',
  async ({
    projectId,
    search = '',
    limit = 100,
    offset = 0,
  }: {
    projectId: string;
    search?: string;
    limit?: number;
    offset?: number;
  }) => {
    const response = await projectGoalsAPI.fetchGoalsForDropdown(
      projectId,
      search,
      limit,
      offset
    );
    return { data: response?.data, offset };
  }
);

export const linkGoalsToProject = createAsyncThunk(
  'projectGoals/LINK_GOALS_TO_PROJECT',
  async (
    { projectId, goalIds }: { projectId: string; goalIds: string[] },
    { rejectWithValue }
  ) => {
    try {
      const response = await projectGoalsAPI.linkProjectToGoals(
        projectId,
        goalIds
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const unlinkGoalsFromProject = createAsyncThunk(
  'projectGoals/UNLINK_GOALS_FROM_PROJECT',
  async (
    { projectId, goalId }: { projectId: string; goalId: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await projectGoalsAPI.unlinkGoalsFromProject(
        projectId,
        goalId
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);
/* ================================= REDUCER ================================ */
const projectGoalsSlice = createSlice({
  name: 'projectGoals',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectGoals.pending, (state) => {
        state.currentProjectGoals.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchProjectGoals.rejected, (state) => {
        state.currentProjectGoals.status = SLICE_STATUS.FAILED;
      })
      .addCase(fetchProjectGoals.fulfilled, (state, action) => {
        state.currentProjectGoals.list = action.payload.goals;
        state.currentProjectGoals.totalCount = action.payload.totalGoals;
        state.currentProjectGoals.status = SLICE_STATUS.IDLE;
      })
      .addCase(fetchGoalsForDropdown.pending, (state) => {
        state.goalsForDropdown.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchGoalsForDropdown.rejected, (state) => {
        state.goalsForDropdown.status = SLICE_STATUS.FAILED;
        throw new Error();
      })
      .addCase(fetchGoalsForDropdown.fulfilled, (state, action) => {
        if (action.payload.offset === 0) {
          state.goalsForDropdown.list = action.payload.data.goals;
        } else {
          state.goalsForDropdown.list = [
            ...state.goalsForDropdown.list,
            ...action.payload.data.goals,
          ];
        }
        state.goalsForDropdown.offset = action.payload.data.nextOffset;
        state.goalsForDropdown.isListExhausted =
          action.payload.data.listExhausted;
        state.goalsForDropdown.status = SLICE_STATUS.IDLE;
      })
      .addCase(linkGoalsToProject.pending, (state) => {
        state.linkingStatus = SLICE_STATUS.LOADING;
      })
      .addCase(linkGoalsToProject.rejected, (state, action) => {
        state.linkingStatus = SLICE_STATUS.FAILED;
        throw new Error(String(action.payload));
      })
      .addCase(linkGoalsToProject.fulfilled, (state) => {
        state.linkingStatus = SLICE_STATUS.IDLE;
      })
      .addCase(unlinkGoalsFromProject.pending, (state) => {
        state.linkingStatus = SLICE_STATUS.LOADING;
      })
      .addCase(unlinkGoalsFromProject.rejected, (state, action) => {
        state.linkingStatus = SLICE_STATUS.FAILED;
        throw new Error(String(action.payload));
      })
      .addCase(unlinkGoalsFromProject.fulfilled, (state) => {
        state.linkingStatus = SLICE_STATUS.IDLE;
      });
  },
});

/* =============================== SELECTORS ================================ */

export const selectProjectGoals = (state: RootState) =>
  state.projectGoals.currentProjectGoals.list;

export const selectTotalProjectGoals = (state: RootState) =>
  state.projectGoals.currentProjectGoals.totalCount;

export const selectProjectGoalsStatus = (state: RootState) =>
  state.projectGoals.currentProjectGoals.status;

export const selectGoalsForDropdown = (state: RootState) =>
  state.projectGoals.goalsForDropdown.list;

export const selectGoalsForDropdownOffset = (state: RootState) =>
  state.projectGoals.goalsForDropdown.offset;

export const selectIsGoalsForDropdownExhausted = (state: RootState) =>
  state.projectGoals.goalsForDropdown.isListExhausted;

export const selectGoalsForDropdownStatus = (state: RootState) =>
  state.projectGoals.goalsForDropdown.status;

export default projectGoalsSlice.reducer;
