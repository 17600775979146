import intl from 'react-intl-universal';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormItem,
  Tooltip,
  UsersPicker,
  IconButton,
  Avatar,
  Typography,
  Tag,
} from '@getsynapse/design-system';
import {
  fetchStrategyOwners,
  selectActiveGoalsOwners,
  selectOriginalStrategyOwners,
} from 'state/StrategyGoals/StrategyGoalsSlice';
import { Option, UserAvatars } from 'utils/customTypes';
import { GoalOwner as GoalOwnerType } from 'utils/types/strategyGoals';
import userAvatar from 'assets/icons/user-avatar.svg';
import classNames from 'classnames';

type GoalOwnerProps = {
  value?: UserAvatars[];
  isRequired?: boolean;
  onChange: (value: string[] | GoalOwnerType[], prop: string) => void;
  newGoal?: boolean;
};

const GoalOwner = ({
  value,
  isRequired = true,
  onChange,
  newGoal = false,
}: GoalOwnerProps) => {
  const dispatch = useDispatch();

  const strategyOwners = useSelector(selectActiveGoalsOwners);
  const originalStrtategyOwners = useSelector(selectOriginalStrategyOwners);

  const isOwnerUserDeleted = value?.length === 0;
  const isGoalOwnerDisabled = value?.[0]?.disabled;

  const selectedUsersList = useMemo(() => {
    if (value?.length) {
      return value;
    }

    if (!newGoal) {
      return [
        {
          avatar: {
            imageSrc: userAvatar,
            initial: '',
            name: '',
            className: 'border-neutral bg-neutral',
          },
          disabled: false,
          label: intl.get('STRATEGY_GOALS.FORMER_USER'),
          value: '',
        },
      ];
    }

    if (strategyOwners.length) {
      return [strategyOwners[0]];
    }
    return [];
  }, [strategyOwners, value, newGoal]);

  useEffect(() => {
    dispatch(fetchStrategyOwners());
  }, [dispatch]);

  useEffect(() => {
    if (newGoal && selectedUsersList?.length) {
      onChange([selectedUsersList[0].value], 'goalOwners');
    }
  }, [onChange, selectedUsersList, newGoal]);

  const changeSelectedUser = (selectedUser: Option) => {
    if (newGoal) {
      onChange([selectedUser.value], 'goalOwners');
    } else {
      const ownerData = originalStrtategyOwners.find(
        (owner) => owner.id === selectedUser.value
      );
      onChange([ownerData!], 'owners');
    }
  };

  return (
    <FormItem
      label={
        <div className='flex justify-center items-center' data-cy='owner_label'>
          {intl.get('STRATEGY_GOALS.ADD_GOAL_MODAL.OWNER')}
          {isRequired && (
            <>
              <span className='text-error-dark'>*</span>
              <Tooltip
                trigger={
                  <IconButton
                    name='information-circle'
                    className='w-4 h-4 text-neutral -top-1 relative'
                    iconClassname='pointer-events-none'
                  />
                }
                className='ml-1'
                openMode='hover1'
                contentProps={{
                  className: 'z-50',
                }}
                timeout={0}
              >
                {intl.get('STRATEGY_GOALS.ADD_GOAL_MODAL.OWNER_TOOLTIP')}
              </Tooltip>
            </>
          )}
        </div>
      }
      className='mb-4'
    >
      {isRequired && (
        <UsersPicker
          usersList={strategyOwners}
          onChange={changeSelectedUser}
          triggerProps={{
            'data-testid': 'owner_dropdown',
          }}
          multiple={false}
          selectedUsersList={selectedUsersList}
        />
      )}
      {!isRequired && (
        <div className='flex'>
          <Avatar
            initial={selectedUsersList[0]?.avatar.initial}
            size='small'
            imageSrc={selectedUsersList[0].avatar.imageSrc}
            name={selectedUsersList[0].label}
            disabled={!isOwnerUserDeleted && isGoalOwnerDisabled}
            showTooltip
            className={
              isOwnerUserDeleted ? 'border-neutral bg-neutral' : undefined
            }
            iconProps={
              isOwnerUserDeleted ? { className: 'w-6 h-6' } : undefined
            }
            data-testid='goal-card_owner-avatar'
            data-cy='owner_value'
          />
          <Typography
            className={classNames('ml-1', {
              'text-primary-dark': isOwnerUserDeleted || isGoalOwnerDisabled,
            })}
          >
            {selectedUsersList[0]?.label}
          </Typography>
          {isGoalOwnerDisabled && (
            <Tag
              label={intl.get('DEACTIVATED')}
              className='bg-neutral-lighter-two ml-1'
              textClassName='text-neutral-dark font-semibold'
            />
          )}
        </div>
      )}
    </FormItem>
  );
};

export default GoalOwner;
